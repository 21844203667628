import { getInstance } from '@/configs/axios';

/**
 *
 * @returns {Promise<Customer[]>}
 */
export const fetchAllCustomers = async () => {
    const { data } = await getInstance().get('customer/get-all');

    return data;
}

/**
 *
 * @returns {Promise<Customer[]>}
 */
export const fetchCustomers = async (page, search, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';

    const { data } = await getInstance().get(`customer/list?page=${page}&search=${search}${sortParams}`);

    return data;
}

/**
 *
 * @param id
 * @returns {Promise<Customer>}
 */
export const fetchCustomer = async (id) => {
    const { data } = await getInstance().get('customer/' + id);

    return data;
}

/**
 *
 * @param {CreateCustomer}form
 * @returns {Promise<any>}
 */
export const createCustomer = async (form) => {
    return await getInstance().post('customer', form, {
        'Content-Type': 'multipart/form-data'
    });
}

/**
 *
 * @param id
 * @param {UpdateCustomer}form
 * @returns {Promise<any>}
 */
export const updateCustomer = async (id, form) => await getInstance().post('customer/' + id, form);

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const deleteCustomer = async (id) => {
    const { data } = await getInstance().delete('customer', { data: { id } });

    return data;
}


/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchCreateApartment =async (id, form) => {
    const { data } = await getInstance().post(`customer/${id}/addApartment`, form);
    return data
}
export const fetchUpdateApartment =async (id, apartment) => {
    const { data } = await getInstance().post(`customer/${id}/updateApartment`, apartment);
    return data
}
export const fetchDeleteApartment =async (id, apartmentId) => {
    const { data } = await getInstance().post(`customer/${id}/removeApartment/${apartmentId}`);
    return data
}
