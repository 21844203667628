import {
    fetchAllCustomers,
    fetchCustomer,
    fetchCustomers,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    fetchCreateApartment,
    fetchUpdateApartment,
    fetchDeleteApartment
} from '@/services/api/customers';

/**
 *
 * @type {{customers: Customer[], customer: Customer}}
 */
const initialState = {
    allCustomers: [],
    customers: [],
    customer: {},
    updateCustomerSuccess: false,
    updateCustomerError: false,
    createCustomerSuccess: false,
    createCustomerError: false,
    customerResponseMessage: '',
    customersCount: 0
};

export default {
    state: initialState,
    getters: {
        getCustomersCount: state => state.customersCount,
        allCustomers: state => state.allCustomers,
        customers: state => state.customers,
        customer: state => state.customer,
        updateCustomerSuccess: state => state.updateCustomerSuccess,
        updateCustomerError: state => state.updateCustomerError,
        customerResponseMessage: state => state.customerResponseMessage,
        createCustomerSuccess: state => state.createCustomerSuccess,
        createCustomerError: state => state.createCustomerError
    },
    mutations: {
        setAllCustomers(state, { data }) {
            state.allCustomers = data;
        },
        setCustomers(state, { data, meta }) {
            state.customers = data;
            state.customersCount = meta.total;
        },
        setCustomer(stat, { data }) {
            stat.customer = data;
        },
        setInitialState(state) {
            state.updateCustomerSuccess = false;
            state.updateCustomerError = false;
            state.createCustomerSuccess = false;
            state.createCustomerError = false;
        },
        setCustomerUpdateSuccess(state, { data, message }) {
            state.customer = data;
            state.updateCustomerSuccess = true;
            state.customerResponseMessage = message;
        },
        setCustomerUpdateError(state, message) {
            state.updateCustomerSuccess = false;
            state.updateCustomerError = true;
            state.customerResponseMessage = message;
        },
        setCustomerCreateSuccess(state, { data, message }) {
            state.customer = data;
            state.createCustomerSuccess = true;
            state.createCustomerError = false;
            state.customerResponseMessage = message;
        },
        setCustomerCreateError(state, message) {
            state.createCustomerError = true;
            state.customerResponseMessage = message;
        }
    },
    actions: {
        async getCustomer({ commit }, id) {
            await commit('setInitialState');
            commit('setCustomer', await fetchCustomer(id));
        },
        async getAllCustomers({ commit }) {
            commit('setAllCustomers', await fetchAllCustomers());
        },
        async getCustomers({ commit }, form) {
            commit('setCustomers', await fetchCustomers(form.page, form.search, form.sortBy, form.sortDesc));
        },
        async createCustomer({ commit }, { form }) {
            await commit('setInitialState');
            const response = await createCustomer(form);
            response.status === 201 ? await commit('setCustomerCreateSuccess', response.data)
                : await commit('setCustomerCreateError', response.data);
        },
        async updateCustomer({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await updateCustomer(id, form);
            response.status === 200 ? await commit('setCustomerUpdateSuccess', response.data)
                : await commit('setCustomerUpdateError', response.data);
        },
        async fetchDeleteCustomer(context, id) {
            return await deleteCustomer(id);
        },
        async addApartment({ commit }, { id, form }){
            await commit('setInitialState')
            commit('setCustomer', await fetchCreateApartment(id, form));
        },
        async updateApartment({ commit }, { id, apartment }){
            await commit('setInitialState')
            commit('setCustomer', await fetchUpdateApartment(id, apartment));
        },
        async deleteApartment({ commit }, { id, apartmentId }){
            await commit('setInitialState')
            commit('setCustomer', await fetchDeleteApartment(id, apartmentId));
        }
    }
};
