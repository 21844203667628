import {
    fetchPublicBoards,
    fetchPublicTask,
    fetchPublicListings
} from '@/services/api/publicBoards';

const initialState = {
   publicBoards: [],
   publicListings: [],
   publicTask: {}
};

export default {
    state: initialState,
    getters: {
        getPublicBoards: state => state.publicBoards,
        getPublicListings: state => state.publicListings,
        getPublicTask: state => state.publicTask
    },
    mutations: {
        setPublicBoards(state, { data }) {
            state.publicBoards = data;
        },
        setPublicListings(state, { data }) {
            state.publicListings = data;
        },
        setPublicTask(state, { data }) {
            state.publicTask = data;
        }
    },
    actions: {
        async fetchPublicBoards({ commit }, { uuid }) {
            const response = await fetchPublicBoards(uuid);
            commit('setPublicBoards', response);
        },
        async fetchPublicListings({ commit }, form) {
            commit('setPublicListings', await fetchPublicListings(form.uuid, form.boardUuid, form.sortBy, form.sortDesc));
        },
        async fetchPublicTask({ commit }, { uuid, taskUuid }) {
            const response = await fetchPublicTask(uuid, taskUuid);
            commit('setPublicTask', response);
        }
    }
}
