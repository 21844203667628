<template>
  <div class="w-full flex flex-col items-start justify-start">
    <div v-for="(message,index) in messages" :key="index" class="font-regular text-error text-14 mt-1">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: ['messages']
}
</script>

<style scoped>

</style>
