import { getInstance } from '@/configs/axios';

/**
 *
 * @param customerId
 * @param form
 * @returns {*}
 */
export const addShareSpace = async (customerId, form) => {
    const { data } = await getInstance().post(`shared-space/${customerId}/item`, { ...form });

    return data;
};


/**
 *
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteShareSpace = async (id) => await getInstance().delete('shared-space', { data: { id } });

/**
 *
 * @param id
 * @param title
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateShareSpace = async (id, title) => await getInstance().patch(`shared-space/${id}`, { title });

/**
 *
 * @param spaceId
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const removeShareSpaceBoard = async (spaceId, id) => await getInstance().patch(`shared-space/${spaceId}/board/detach`, { id });

/**
 *
 * @param spaceId
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addSpaceBoard = async (spaceId, id) => await getInstance().patch(`shared-space/${spaceId}/board/attach`, { id });




