import {
    fetchAccount,
    fetchDeleteAccountAvatar,
    fetchUpdateAccountSettings,
    fetchUploadAccountImage,
    fetchUpdateCalendarIdSettings
} from '@/services/api/account';
import { setUserCredentials } from '@/configs/axios/account';

export default {
    state: {
        account: {},
        updateAccountSuccess: false,
        updateAccountError: false,
        updateAccountResponseMessage: {},
        avatar: '',
        updateAvatarSuccess: false,
        updateAvatarError: false,
        updateAvatarResponseMessage: {}
    },
    getters: {
        account: state => state.account,
        updateAccountSuccess: state => state.updateAccountSuccess,
        updateAccountError: state => state.updateAccountError,
        updateAccountResponseMessage: state => state.updateAccountResponseMessage,
        avatar: state => state.avatar,
        updateAvatarSuccess: state => state.updateAvatarSuccess,
        updateAvatarError: state => state.updateAvatarError,
        updateAvatarResponseMessage: state => state.updateAvatarResponseMessage
    },
    mutations: {
        async setAccount(state, { data }) {
            state.account = data;
            await setUserCredentials(data.isadmin);
        },
        setInitialState(state) {
            state.updateAccountSuccess = false;
            state.updateAccountError = false;
            state.updateAccountResponseMessage = {};
            state.avatar = '';
            state.updateAvatarSuccess = false;
            state.updateAvatarError = false;
            state.updateAvatarResponseMessage = {};
        },
        changeAccountAvatar(state, { message, data }) {
            state.account = {
                ...state.account,
                avatar: data.avatar
            };
            state.updateAvatarResponseMessage = message;
            state.updateAvatarSuccess = true;
        },
        setUpdateAccountSuccess(state, { data, message }) {
            state.updateAccountSuccess = true;
            state.updateAccountResponseMessage = message;
            state.account = data;
        },
        setUpdateAccountError(state, message) {
            state.updateAccountError = true;
            state.updateAccountResponseMessage = message;
        }
    },
    actions: {
        async getAccount({ commit }) {
            commit('setAccount', await fetchAccount());
        },
        async uploadAccountImage({ commit }, form) {
            commit('setInitialState');
            commit('changeAccountAvatar', await fetchUploadAccountImage(form));
        },
        async deleteAccountAvatar() {
            return await fetchDeleteAccountAvatar();
        },
        async updateAccountSettings({ commit }, form) {
            commit('setInitialState');
            const response = await fetchUpdateAccountSettings(form);
            response.status === 200 ? await commit('setUpdateAccountSuccess', response.data)
                : await commit('setUpdateAccountError', response.data);
        },
        async updateCalendarId({ commit }, form) {
            commit('setInitialState');
            const response = await fetchUpdateCalendarIdSettings(form);
            response.status === 200 ? await commit('setUpdateAccountSuccess', response.data)
                : await commit('setUpdateAccountError', response.data);
        }
    }
}
