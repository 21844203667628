import {
    fetchCalendarEvents,
    fetchEventProcess,
    fetchGetTasksByCustomerId,
    fetchUpdateEventProcess,
    syncCalendarEvents,
    fetchUpdateEventStatus,
    userCalendarEvents,
    fetchGoogleCalendarEvents,
    saveCalendarEvents, deleteCalendarEvents
} from '@/services/api/calendar';
import i18n from '../../i18n'

const initialState = {
    events: [],
    customersTasks: [],
    targetEvent: {},
    updateEventSuccess: false,
    updateEventError: false,
    updateEventRequestMessage: '',
    eventProcessSuccess: false,
    eventProcessError: false,
    eventProcessRequestMessage: '',
    syncCalendarEventsSuccess: false,
    syncCalendarEventsError: false,
    syncCalendarEventsResponseMessage: '',
    userCalendarExist:false,
    userCalendarId:null,
    closeEventModal:false
};

export default {
    state: initialState,
    getters: {
        events: state => state.events,
        customersTasks: state => state.customersTasks,
        updatedTargetEvent: state => state.targetEvent,
        updateEventSuccess: state => state.updateEventSuccess,
        updateEventError: state => state.updateEventError,
        updateEventRequestMessage: state => state.updateEventRequestMessage,
        eventProcessSuccess: state => state.eventProcessSuccess,
        eventProcessError: state => state.eventProcessError,
        eventProcessRequestMessage: state => state.eventProcessRequestMessage,
        syncCalendarEventsSuccess: state => state.syncCalendarEventsSuccess,
        syncCalendarEventsError: state => state.syncCalendarEventsError,
        syncCalendarEventsResponseMessage: state => state.syncCalendarEventsResponseMessage,
        getUserCalendarExist: state => state.userCalendarExist,
        closeEventModal: state => state.closeEventModal
    },
    mutations: {
        setEvents(state, { data }) {
            state.events = data;
        },
        setTasks(state, { data }) {
            state.customersTasks = data;
        },
        setUpdateEventInitialState(state) {
            state.targetEvent = {};
            state.updateEventSuccess = false
            state.updateCustomerError = false;
            state.updateEventRequestMessage = '';
            state.closeEventModal = false
        },
        setUpdateEventSuccess(state, { data, message }) {
            state.targetEvent = data;
            state.updateEventSuccess = true;
            state.updateEventError = false;
            state.updateEventRequestMessage = message;
        },
        setUpdateEventError(state, message) {
            state.updateEventSuccess = false;
            state.updateEventError = true;
            state.updateEventRequestMessage = message;
        },
        setEventProcessInitialState(state) {
            state.targetEvent = {};
            state.eventProcessSuccess = false;
            state.eventProcessError = false;
            state.eventProcessRequestMessage = '';
            state.closeEventModal = false
        },
        setEventProcessSuccess(state, { data, message }) {
            state.targetEvent = data;
            state.eventProcessSuccess = true;
            state.eventProcessError = false;
            state.eventProcessRequestMessage = message;
        },
        setEventProcessError(state, message) {
            state.eventProcessSuccess = false;
            state.eventProcessError = true;
            state.eventProcessRequestMessage = message;
        },
        syncCalendarEventsSuccess(state, { data, message }) {
            state.events = data;
            state.syncCalendarEventsSuccess = true;
            state.syncCalendarEventsError = false;
            state.syncCalendarEventsResponseMessage = message;
        },
        syncCalendarEventsError(state, { message }) {
            state.syncCalendarEventsSuccess = false;
            state.syncCalendarEventsError = true;
            state.syncCalendarEventsResponseMessage = message;
        },
        syncCalendarEventsSyncError(state, { error }) {
            const message = `. ${i18n.t('calendar.notification.calendar_enable')}`
            state.syncCalendarEventsSuccess = false;
            state.syncCalendarEventsError = true;
            state.syncCalendarEventsResponseMessage = error.message + (error.status ? '' : message);
        },
        setSyncCalendarEventsInitialState(state) {
            state.syncCalendarEventsSuccess = false;
            state.syncCalendarEventsError = false;
            state.syncCalendarEventsResponseMessage = '';
            state.closeEventModal = false
        },
        userCalendarExist(state, data){
            state.userCalendarExist = true
            state.userCalendarId = data
        },
        userCalendarNotExist(state, data){
            state.userCalendarExist = false
            state.userCalendarId = data
        },
        setCloseEventModal(state){
            state.closeEventModal = true
        },
        setOpenEventModal(state){
            state.closeEventModal = false
        },
        deleteEvents() {
            initialState.events = []
        },
        deleteEvent(state, data) {
            console.log(data, state)
        }
    },
    actions: {
        async fetchCalendarEvents({ commit }) {
            commit('setEvents', await fetchCalendarEvents());
        },
        async fetchGetTasksByCustomerId({ commit }, id) {
               commit('setTasks', await fetchGetTasksByCustomerId(id));
        },
        async fetchEventProcess({ commit }, { form, id }) {
            const { status, data } = await fetchEventProcess(id, form);
            status === 200 ? await commit('setEventProcessSuccess', data) : await commit('setEventProcessError', data);
        },
        async fetchUpdateEventProcess({ commit }, { form, id }) {
            await commit('setUpdateEventInitialState');
            const { status, data } = await fetchUpdateEventProcess(id, form);
            status === 200 ? await commit('setUpdateEventSuccess', data) : await commit('setUpdateEventError', data);
        },
        async fetchUpdateEventStatus({ commit }, { eventStatus, id }) {
            await commit('setUpdateEventInitialState');
            const { status, data } = await fetchUpdateEventStatus(id, { status:eventStatus });
            status === 200 ? await commit('setUpdateEventSuccess', data) : await commit('setUpdateEventError', data);
        },

        async syncCalendarEvents({ commit }) {
            commit('setSyncCalendarEventsInitialState');
            const { status, data } = await syncCalendarEvents();
            if (status === 200){
                await commit('syncCalendarEventsSuccess', data)
            }
            status === 200 ? await commit('syncCalendarEventsSuccess', data) : await commit('syncCalendarEventsError', data);
        },
        async fetchGoogleCalendarEvents({ commit, dispatch, state }, token) {
            commit('setSyncCalendarEventsInitialState');
            const apiKey = process.env.VUE_APP_GOOGLE_API_KEY
            const { status, data } = await fetchGoogleCalendarEvents(state.userCalendarId, apiKey, token)
            if (status === 200){
                await dispatch('saveCalendarEvents', data)
            } else if (status === 401){
                localStorage.removeItem('accessToken')
                data.message =i18n.t('calendar.notification.google_account_login')
                await commit('syncCalendarEventsError', data)
            } else {
                await commit('syncCalendarEventsSyncError', data)
            }
        },
        async saveCalendarEvents({ commit }, events){
            commit('setSyncCalendarEventsInitialState');
            const { status, data } = await saveCalendarEvents({ events });
            status === 200 ? await commit('syncCalendarEventsSuccess', data) : await commit('syncCalendarEventsError', data);
        },
        async userCalendarEvents({ commit }) {
            commit('setSyncCalendarEventsInitialState');
            const { status, data } = await userCalendarEvents();
            status === 200 ? await commit('userCalendarExist', data.data) : await commit('userCalendarNotExist', null);
        },
        async deleteCalendarEvents({ commit }) {
            await deleteCalendarEvents();
            commit('deleteEvents');
        },
        async deleteEvent(id) {}
    }
}
