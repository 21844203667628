import { getInstance } from '@/configs/axios';

/**
 *
 * @returns {Promise<any>}
 */
export const fetchInvoiceSummaries = async (page, search, sortBy = null, sortDesc = null, year, month, deliveredBy = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';
    const yearParams = year ? `&year=${year}` : '';
    const monthParams = year ? `&month=${month}` : '';
    const deliveredByParams = deliveredBy ? `&deliveredBy=${deliveredBy}` : '';
    const { data } = await getInstance().get(`invoice/summary?page=${page}&search=${search}${sortParams}${yearParams}${monthParams}${deliveredByParams}`);
    return data;
};
