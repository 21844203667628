import Vue from 'vue';
import Router from 'vue-router';
import auth from './auth.js'
import products from './product.js';
import account from './account.js';
import customers from './customer.js';
import board from './board.js';
import notFound from '@/views/NotFound/NotFound';
import home from './home.js';
import publicPages from './publicPages';
import invoices from './invoice';
import myTask from './myTask';
import calendar from '@/router/calendar';
import users from '@/router/users'
import labels from '@/router/labels'
import invoiceConnectors from '@/router/invoiceConnectors';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        ...auth,
        ...products,
        ...account,
        ...customers,
        ...board,
        ...home,
        ...publicPages,
        ...invoices,
        ...myTask,
        ...calendar,
        ...users,
        ...labels,
        ...invoiceConnectors,
        { path: '*', component: notFound }
    ]
});

/**
 *
 * @param context
 * @param middleware
 * @param index
 * @returns {(function(...[*]): void)|*}
 */
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Than run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    (!to.meta.auth && to.fullPath === '/') && next({ path: '/login' });
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

        const context = { from, next, router, to };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
