import Login from '@/views/Auth/login/Login';
import ResetPassword from '@/views/Auth/ResetPassword/ResetPassword';
import ForgotPassword from '@/views/Auth/ForgotPassword/ForgotPassword';

export default [
    {
        path: '/',
        alias: '/login',
        component: Login,
        name: 'login',
        meta: {
            auth: false,
            title: 'account.header.title'
        }
    },
    {
        path: '/password/forgot',
        name: 'password-forgot',
        component: ForgotPassword,
        meta: {
            auth: false,
            title: 'account.header.title'
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            auth: false,
            title: 'account.header.title'
        }
    }
]
