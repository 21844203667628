import { fetchComment } from '@/services/api/comment';


const initialState = {
    comment: {},
    createCommentSuccess: false,
    createCommentError: false,
    createCommentResponseMessage: ''
};

export default {
    state: initialState,
    getters: {
        getCreatedComment: state => state.comment,
        createCommentSuccess: state => state.createCommentSuccess,
        createCommentResponseMessage: state => state.createCommentResponseMessage
    },
    mutations: {
        setCommentCreateSuccess(state, { data, message }) {
            state.comment = data;
            state.createCommentSuccess = true;
            state.createCommentResponseMessage = message;
            state.createCommentError = false;
        },
        setCommentCreateError(state, message) {
            state.createCommentSuccess = false;
            state.createCommentError = true;
            state.createCommentResponseMessage = message;
        },
        setInitialState(state) {
            state.createCommentSuccess = false;
            state.createCommentError = false;
            state.createCommentResponseMessage = '';
            state.comment = {};
        }
    },
    actions: {
        async createNewComment({ commit }, { comment, prefix, id }) {
            await commit('setInitialState');
            commit('setCommentCreateSuccess', await fetchComment(comment, prefix, id));
        }
    }
}
