import {
    createGoogleAttachmentFile,
    deleteAttachmentFile,
    createAttachmentFile,
    deleteGoogleAttachmentFile
} from '@/services/api/attachmentFile';

const initialState = {
    createAttachmentFileSuccess: false,
    createAttachmentFileError: false,
    attachedFiles: [],
    deleteAttachmentFileSuccess: false,
    deleteAttachmentFileError: false,
    attachmentFileResponseMessage: '',
    attachedGoogleFiles: [],
    createGoogleFileSuccess: false,
    createGoogleFileError: false,
    deleteGoogleFileSuccess: false,
    deleteGoogleFileError: false
};

export default {
    state: initialState,
    getters: {
        createAttachmentFileSuccess: state => state.createAttachmentFileSuccess,
        createAttachmentFileError: state => state.createAttachmentFileError,
        attachedFilesList: state => state.attachedFiles,
        deleteAttachmentFileSuccess: state => state.deleteAttachmentFileSuccess,
        deleteAttachmentFileError: state => state.deleteAttachmentFileError,
        attachmentFileResponseMessage: state => state.attachmentFileResponseMessage,
        createGoogleFileSuccess: state => state.createGoogleFileSuccess,
        createGoogleFileError: state => satte.createGoogleFileError,
        attachedGoogleFiles: state => state.attachedGoogleFiles,
        deleteGoogleFileSuccess: state => state.deleteGoogleFileSuccess,
        deleteGoogleFileError: state => state.deleteGoogleFileError
    },
    mutations: {
        setInitialState(state) {
            state.createAttachmentFileSuccess = false;
            state.createAttachmentFileError = false;
            state.deleteAttachmentFileSuccess = false;
            state.deleteAttachmentFileError = false;
            state.createGoogleFileSuccess = false;
            state.createGoogleFileError = false;
            state.deleteGoogleFileSuccess = false;
            state.deleteGoogleFileError = false;
            state.attachmentFileResponseMessage = '';
        },
        setCreateAttachmentFileSuccess(state, { data, message }) {
            state.createAttachmentFileSuccess = true;
            state.createAttachmentFileError = false;
            state.attachedFiles = data.files;
            state.attachmentFileResponseMessage = message;
        },
        setCreateAttachmentFileError(state, message) {
            state.createAttachmentFileSuccess = false;
            state.createAttachmentFileError = true;
            state.attachmentFileResponseMessage = message;
        },
        setDeleteAttachmentFileSuccess(state, { message }) {
            state.deleteAttachmentFileSuccess = true;
            state.deleteAttachmentFileError = false;
            state.attachmentFileResponseMessage = message;
        },
        setDeleteAttachmentFileError(state, message) {
            state.deleteAttachmentFileSuccess = false;
            state.deleteAttachmentFileError = true;
            state.attachmentFileResponseMessage = message;
        },
        setCreateGoogleFileSuccess(state, { data, message }) {
            state.createGoogleFileSuccess = true;
            state.createGoogleFileError = false;
            state.attachedGoogleFiles = data.google_files;
            state.attachmentFileResponseMessage = message;
        },
        setCreateFileError(state, message) {
            state.createGoogleFileError = true;
            state.createGoogleFileSuccess = true;
            state.attachmentFileResponseMessage = message;
        },
        setDeleteGoogleFileSuccess(state, { message }) {
            state.deleteGoogleFileSuccess = true;
            state.deleteGoogleFileError = false;
            state.attachmentFileResponseMessage = message;
        },
        setDeleteGoogleFileError(state, message) {
            state.deleteGoogleFileError = true;
            state.deleteGoogleFileSuccess = false;
            state.attachmentFileResponseMessage = message;
        }
    },
    actions: {
        async deleteAttachmentFile({ commit }, { prefix, ownerId, id }) {
            await commit('setInitialState');
            const response = await deleteAttachmentFile(prefix, ownerId, id);
            response.status === 200 ? await commit('setDeleteAttachmentFileSuccess', response.data)
                : await commit('setDeleteAttachmentFileError', response.data);
        },
        async createAttachmentFile({ commit }, { prefix, ownerId, form }) {
            await commit('setInitialState');
            const response = await createAttachmentFile(prefix, ownerId, form);
            response.status === 200 ? await commit('setCreateAttachmentFileSuccess', response.data)
                : await commit('setCreateFileError', response.data);
        },
        async createGoogleAttachmentFile({ commit }, { prefix, ownerId, form }) {
            await commit('setInitialState');
            const response = await createGoogleAttachmentFile(prefix, ownerId, form);
            response.status === 200 ? await commit('setCreateGoogleFileSuccess', response.data)
                : await commit('setGoogleAttachmentFileError', response.data);
        },
        async deleteGoogleAttachmentFile({ commit }, { prefix, ownerId, id }) {
            await commit('setInitialState');
            const response = await deleteGoogleAttachmentFile(prefix, ownerId, id);
            response.status === 200 ? await commit('setDeleteGoogleFileSuccess', response.data)
                : await commit('setDeleteGoogleFileError', response.data);
        }
    }
}
