import {
    addShareSpace,
    addSpaceBoard,
    deleteShareSpace,
    removeShareSpaceBoard,
    updateShareSpace
} from '@/services/api/shareSpace';

export default {
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        async addShareSpace({ commit }, params) {
            const { customerId, form } = params;
            return await addShareSpace(customerId, form);
        },
        async deleteShareSpace(context, params) {
            const { id } = params;
            return await deleteShareSpace(id);
        },
        async updateShareSpace({ commit }, params) {
            const { id, title } = params;
            return await updateShareSpace(id, title);
        },
        async removeSpaceBoard({ commit }, params) {
            const { spaceId, id } = params;
            return await removeShareSpaceBoard(spaceId, id);
        },
        async addSpaceBoard({ commit }, params) {
            const { spaceId, id } = params;
            return await addSpaceBoard(spaceId, id);
        }
    }
}
