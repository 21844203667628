<template>
    <transition
            :enter-active-class="transition.enter"
            :leave-active-class="transition.leave">
      <div
          aria-live="assertive"
          class="fixed inset-0 flex justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end notification"
          @click="whenClicked"
          @mouseleave="toggleTimer(false)"
          @mouseover="toggleTimer(true)"
          v-show="isActive"
      >
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg v-if="type === 'success'" class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <img v-else-if="type === 'error'" :src="require('../../../../assets/icons/warning-sign.svg')" alt="" class="w-7 h-7">
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ message }}
                </p>
                <p v-if="description" class="mt-1 text-sm text-gray-500">
                  {{ description }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: solid/x -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
  import { removeElement } from './helpers.js';
  import Timer from './timer.js';
  import Positions from './positions.js';
  import eventBus from './bus.js';
  import '../themes/sugar/index.scss';

  export default {
    name: 'toast',
    props: {
      message: {
        type: String,
        required: true
      },
      description: {
        type: String
      },
      type: {
        type: String,
        'default': 'success'
      },
      position: {
        type: String,
        'default': Positions.BOTTOM_RIGHT,
        validator(value) {
          return Object.values(Positions).includes(value)
        }
      },
      duration: {
        type: Number,
        'default': 6000
      },
      dismissible: {
        type: Boolean,
        'default': true
      },
      onDismiss: {
        type: Function,
        'default': () => {
        }
      },
      onClick: {
        type: Function,
        'default': () => {
        }
      },
      queue: Boolean,
      pauseOnHover: {
        type: Boolean,
        'default': true
      }
    },
    data() {
      return {
        isActive: false,
        parentTop: null,
        parentBottom: null,
        isHovered: false
      }
    },
    beforeMount() {
      this.setupContainer()
    },
    mounted() {
      this.showNotice()
      eventBus.on('toast-clear', this.dismiss)
    },
    methods: {
      setupContainer() {
        this.parentTop = document.querySelector('.v-toast.v-toast--top')
        this.parentBottom = document.querySelector('.v-toast.v-toast--bottom')
        // No need to create them, they already exists
        if (this.parentTop && this.parentBottom) return

        if (!this.parentTop) {
          this.parentTop = document.createElement('div')
          this.parentTop.className = 'v-toast v-toast--top'
        }

        if (!this.parentBottom) {
          this.parentBottom = document.createElement('div')
          this.parentBottom.className = 'v-toast v-toast--bottom'
        }
        const container = document.body
        container.appendChild(this.parentTop)
        container.appendChild(this.parentBottom)
      },

      shouldQueue() {
        if (!this.queue) return false

        return (
          this.parentTop.childElementCount > 0 ||
          this.parentBottom.childElementCount > 0
        )
      },

      dismiss() {
        this.timer.stop()
        clearTimeout(this.queueTimer)
        this.isActive = false

        // Timeout for the animation complete before destroying
        setTimeout(() => {
          this.onDismiss.apply(null, arguments)
          this.$destroy()
          removeElement(this.$el)
        }, 150)
      },

      showNotice() {
        if (this.shouldQueue()) {
          // Call recursively if should queue
          this.queueTimer = setTimeout(this.showNotice, 250)
          return
        }
        this.correctParent.insertAdjacentElement('afterbegin', this.$el)
        this.isActive = true

        this.timer = new Timer(this.dismiss, this.duration)
      },

      whenClicked() {
        if (!this.dismissible) return
        this.onClick.apply(null, arguments)
        this.dismiss()
      },
      toggleTimer(newVal) {
        if (!this.pauseOnHover) return
        newVal ? this.timer.pause() : this.timer.resume()
      }
    },
    computed: {
      correctParent() {
        switch (this.position) {
          case Positions.TOP:
          case Positions.TOP_RIGHT:
          case Positions.TOP_LEFT:
            return this.parentTop

          case Positions.BOTTOM:
          case Positions.BOTTOM_RIGHT:
          case Positions.BOTTOM_LEFT:
            return this.parentBottom
        }
      },
      transition() {
        switch (this.position) {
          case Positions.TOP:
          case Positions.TOP_RIGHT:
          case Positions.TOP_LEFT:
            return {
              enter: 'v-toast--fade-in-down',
              leave: 'v-toast--fade-out'
            }

          case Positions.BOTTOM:
          case Positions.BOTTOM_RIGHT:
          case Positions.BOTTOM_LEFT:
            return {
              enter: 'v-toast--fade-in-up',
              leave: 'v-toast--fade-out'
            }
        }
      }
    },
    beforeDestroy() {
      eventBus.off('toast-clear', this.dismiss)
    }
  }
</script>
<style scoped>
  .notification {
    align-items: flex-end;
  }
</style>
