import { getInstance } from '@/configs/axios';

/**
 *
 * @returns {Promise<any>}
 */
export const fetchBoardsOption = async () => {
    const { data } = await getInstance().get('board/options');

    return data;
};

/**
 *
 * @returns {Promise<any>}
 */
export const fetchAllBoards = async () => {
    const { data } = await getInstance().get('board/get-all');

    return data;
}

/**
 *
 * @param id
 * @param board_id
 * @param move
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUpdateBoardCardPosition = (id, board_id, move) => getInstance().patch(`board/${id}/position`, { board_id, move });

/**
 *
 * @param id
 * @param listing_id
 * @param move
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchChangeListingPosition = (id, listing_id, move) => getInstance().patch(`listing/${id}/position`, { listing_id, move });

/**
 *
 * @param form
 * @returns {Promise<any>}
 */
export const fetchCreateBoard = async (form) => {
    const { data } = await getInstance().post('board/create', { ...form });

    return data;
}

export const fetchBoardTasks = async (id) => {
    const { data } = await getInstance().get(`board/${id}/all-tasks`);

    return data;
}
export const fetchListingTasks = async (id) => {
    const { data } = await getInstance().get(`listing/${id}`);

    return data;
}
export const fetchSearchedTasks = async (id, search) => {
    const { data } = await getInstance().patch(`listing/${id}/search?search=${search}`);

    return data;
}

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const fetchBoard = async (id) => {
    const { data } = await getInstance().get(`board/${id}`);

    return data;
};
/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const fetchSearchedByStatus = async (id, searchQuery) => {
    const { data } = await getInstance().get(`board/${id}`, {
        params: {
            search: searchQuery
        }
    });

    return data;
};

/**
 *
 * @param id
 * @param form
 * @returns {Promise<any>}
 */
export const fetchCreateBoardListing = async (id, form) => {
    const { data } = await getInstance().post(`board/${id}/listing`, { ...form });

    return data;
}

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUpdateBoard = (id, form) => getInstance().patch(`board/${id}`, { ...form });

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchDeleteBoard = (id) => getInstance().delete(`board/${id}`);

/**
 *
 * @returns {Promise<any>}
 */
export const fetchAllListing = async () => {
    const { data } = await getInstance().get('board/all-listing');

    return data;
}

export const searchTasksByGroup = async (type, searchQuery) => {
    const { data } = await getInstance().get('board/all-listing');

    return data;
}


/**
 *
 * @returns {Promise<any>}
 */
export const deleteListing = (id) => getInstance().delete(`listing/${id}`, { data: { id } })

/**
 *
 * @returns {Promise<any>}
 */
export const updateListingData = (data) => getInstance().patch(`listing/${data.id}`, { name: data.name })
