import auth from '@/router/middlewares/auth';

const staticPathName = 'my-tasks';

export default [
    {
        path: '/my-tasks',
        component: () => import('@/views/MyTask/MyTask'),
        meta: {
            breadCrumb: 'myTask.header.breadCrumb'
        },
        children: [
            {
                path: '/',
                name: 'my-tasks',
                component: () => import('@/views/MyTask/MyTaskTable/MyTaskBoard'),
                meta: {
                    headerBackToActions: false,
                    headerAddNewAction: false,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title',
                    breadCrumbHidden: false
                }
            },
            {
                path: ':id',
                name: 'my-task-listings',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/MyTask/MyTaskTable/MyTaskTable'),
                meta: {
                    headerBackToActions: true,
                    headerIsCreateTaskActions: false,
                    breadCrumbDisable: false,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title'
                }
            },
            {
                path: 'create',
                name: 'my-task-create',
                component: () => import('@/views/Task/CreateOrUpdate/CreateOrUpdate'),
                meta: {
                    auth: true,
                    middleware: auth,
                    breadCrumb: 'task.header.create',
                    headerBackToActions: true,
                    breadCrumbDisable: true,
                    saveAction: true,
                    staticPathName,
                    title: 'account.header.title'
                }
            }
        ]
    }
];
