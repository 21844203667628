<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-linter text-gray-900">
        {{ $t('account.resetPassword') }}
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="mt-8 space-y-6">
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <FormInput
                  label="Email address"
                  inputName="email"
                  type="email"
                  :value="form.email"
                  :errorMessage="formErrors.email"
                  @handleInputChange="handleInputChange"
              />
            </div>
          </div>
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <FormInput
                  label="Password"
                  type="password"
                  inputName="password"
                  :value="form.password"
                  :errorMessage="formErrors.password"
                  @handleInputChange="handleInputChange"
              />
            </div>
          </div>
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <FormInput
                  label="Confirm Password"
                  type="password"
                  inputName="password_confirmation"
                  :value="form.password_confirmation"
                  @handleInputChange="handleInputChange"
              />
            </div>
          </div>
          <div>
            <MainButton
                name="Send New Password"
                @clickAction="resetPassword"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    MainButton,
    FormInput
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
      },
      formErrors: {
        password: [],
        email: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'createNewPasswordSuccess',
      'createNewPasswordError',
      'createNewPasswordResponseMessage'
    ])
  },
  mounted() {
    this.form.token = this.$route.query.token;
  },
  watch: {
    createNewPasswordSuccess(newValue) {
      if (newValue) {
        this.$router.push('/login');
        this.toastMessage(this.createNewPasswordResponseMessage, 'success');
      }
    },
    createNewPasswordError(newValue) {
      newValue && this.handleRequestErrorMessage(newValue);
    }
  },
  methods: {
    ...mapActions({
      handleCreateNewPassword: 'createNewPassword'
    }),
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
    },
    resetPassword() {
      this.handleCreateNewPassword({ form: this.form });
    },
    handleRequestErrorMessage(isError) {
      if (isError) {
        const { message, errors } = this.createNewPasswordResponseMessage;
        this.formErrors = { ...this.formErrors, ...errors };
        this.toastMessage(message, 'error');
      }
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style scoped>

</style>
