import {
    fetchInvoiceSummaries
} from '@/services/api/invoiceSummary';

const initialState = {
    summaries: [],
    summary: {}
    //selectInvoiceType:'none'
};

export default {
    state: initialState,
    getters: {
        summaries: state => state.summaries,
        summary: state => state.summary
        //selectInvoiceType: state => state.selectInvoiceType
    },
    mutations: {
        setInvoiceSummaries(state, data) {
            state.summaries = data;
        },
        setInvoice(state, { data }) {
            state.summary = data.data;
        },
        setSelectInvoiceType(state, type) {

            state.selectInvoiceType = type
        }
    },
    actions: {
        async fetchInvoiceSummaries({ commit }, form) {
            commit('setInvoiceSummaries', await fetchInvoiceSummaries(form.page, form.search, form.sortBy, form.sortDesc, form.year, form.month, form.deliveredBy));
        }
    }
}
