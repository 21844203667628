import auth from '@/router/middlewares/auth';

//@TODO Need to refactor this home page rendering
export default [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "BoardView" */'@/views/Boards/Boards'),
        meta: {
            breadCrumb: 'board.header.breadCrumb',
            breadCrumbHidden: true
        },
        children: [
            {
                path: '/home',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Boards/BoardsList/BoardsList'),
                name: 'home',
                meta: {
                    headerBackToActions: false,
                    headerIsCreateBoardDropDown: true,
                    auth: true,
                    middleware: auth,
                    title: 'account.header.title'
                }
            }
        ]
    }
];
