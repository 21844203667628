import { fetchNewPassword, fetchResetPassword } from '@/services/api/auth/resetPassword';

/**
 *
 * @type {{
 * resetPasswordError: boolean,
 * resetPasswordResponseMessage: {},
 * createNewPasswordSuccess: boolean,
 * createNewPasswordResponseMessage: {},
 * createNewPasswordError: boolean,
 * resetPasswordSuccess: boolean
 * }}
 */
const initialState = {
    resetPasswordSuccess: false,
    resetPasswordError: false,
    resetPasswordResponseMessage: {},
    createNewPasswordSuccess: false,
    createNewPasswordError: false,
    createNewPasswordResponseMessage: {}
};

export default {
    state: initialState,
    getters: {
      resetPasswordSuccess: state => state.resetPasswordSuccess,
      resetPasswordError: state => state.resetPasswordError,
      resetPasswordResponseMessage: state => state.resetPasswordResponseMessage,
      createNewPasswordSuccess: state => state.createNewPasswordSuccess,
      createNewPasswordError: state => state.createNewPasswordError,
      createNewPasswordResponseMessage: state => state.createNewPasswordResponseMessage
    },
    mutations: {
        setInitialState(state) {
            state.resetPasswordSuccess = false;
            state.resetPasswordError = false;
            state.resetPasswordResponseMessage = {};
            state.createNewPasswordSuccess = false;
            state.createNewPasswordError = false;
            state.createNewPasswordResponseMessage = {};
        },
        setResetPasswordSuccess(state, { message }) {
            state.resetPasswordSuccess = true;
            state.resetPasswordResponseMessage = message;
        },
        setResetPasswordError(state, message) {
            state.resetPasswordError = true;
            state.resetPasswordResponseMessage = message;
        },
        setCreateNewPasswordSuccess(state, { message }) {
            state.createNewPasswordSuccess = true;
            state.createNewPasswordResponseMessage = message;
        },
        setCreateNewPasswordError(state, message) {
            state.createNewPasswordError = true;
            state.createNewPasswordResponseMessage = message;
        }
    },
    actions: {
        async resetPassword({ commit }, email) {
            await commit('setInitialState');
            const response = await fetchResetPassword(email);
            response.status === 200 ? await commit('setResetPasswordSuccess', response.data)
                : await commit('setResetPasswordError', response.data)
        },
        async createNewPassword({ commit }, form) {
            await commit('setInitialState');
            const response = await fetchNewPassword(form);
            response.status === 200 ? await commit('setCreateNewPasswordSuccess', response.data)
                : commit('setCreateNewPasswordError', response.data);
        }
    }
}
