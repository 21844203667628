import { getInstance } from '@/configs/axios';
import { CSRFRequest } from '@/services/api/auth/csrf';


/**
 *
 * @param email
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchResetPassword = async (email) => {
    await CSRFRequest();
    return await getInstance().post('password/email', email);
}

/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchNewPassword = async ({ form }) => {
    await CSRFRequest();
    return await getInstance().post('password/reset', form);
}
