import { getInstance } from '@/configs/axios';

/**
 *
 * @returns {Promise<InvoiceConnector[]>}
 */
export const fetchInvoiceConnectors = async () => {
  const { data } = await getInstance().get('invoice-connectors');

  return data;
}

/**
 *
 * @param id
 * @returns {Promise<InvoiceConnector>}
 */
export const fetchInvoiceConnector = async (id) => {
  const { data } = await getInstance().get('invoice-connectors/' + id);
  return data;
}

/**
 *
 * @param {CreateInvoiceConnector}form
 * @returns {Promise<any>}
 */
export const createInvoiceConnector = async (form) => {
  return await getInstance().post('invoice-connectors', form, {
    'Content-Type': 'multipart/form-data'
  });
}

/**
 *
 * @param id
 * @param {UpdateInvoiceConnector}form
 * @returns {Promise<any>}
 */
export const updateInvoiceConnector = async (form) => {
 return await getInstance().patch('invoice-connectors/' + form.id, form);
}


/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const deleteInvoiceConnector = async (id) => {
  const { data } = await getInstance().delete(`/invoice-connectors/${id.id}`, { data: { id } });

  return data;
}


/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchCreateApartment =async (id, form) => {
  const { data } = await getInstance().post(`invoice-connectors/${id}/addApartment`, form);
  return data
}
export const fetchUpdateApartment =async (id, apartment) => {
  const { data } = await getInstance().post(`invoice-connectors/${id}/updateApartment`, apartment);
  return data
}
export const fetchDeleteApartment =async (id, apartmentId) => {
  const { data } = await getInstance().post(`invoice-connectors/${id}/removeApartment/${apartmentId}`);
  return data
}
