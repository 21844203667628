import auth from '@/router/middlewares/auth';
import isadmin from '@/router/middlewares/isadmin';

const staticPathName = 'invoiceConnectors';

export default [
  {
    path: '/invoice-connectors',
    component: () => import(/* webpackChunkName: "InvoiceConnectorView" */ '@/views/InvoiceConnectors/InvoiceConnectors'),
    meta: {
      middleware: [auth, isadmin],
      breadCrumb: 'invoiceConnector.header.table'
    },
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "InvoiceConnectorView" */ '@/views/InvoiceConnectors/InvoiceConnectorsTable/InvoiceConnectorsTable'),
        name: 'invoice-connector',
        meta: {
          headerBackToActions: true,
          headerAddNewAction: true,
          auth: true,
          middleware: [auth, isadmin],
          staticPathName,
          title: 'account.header.title'
        }
      }
    ]
  },
  {
    path: '/invoice-connector',
    component: () => import(/* webpackChunkName: "InvoiceConnectorView" */ '@/views/InvoiceConnectors/InvoiceConnectors'),
    meta: {
      middleware: [auth, isadmin],
      breadCrumb: 'invoiceConnector.header.table'
    },
    children: [
      {
        path: 'create',
        name: 'create',
        component: () => import(/* webpackChunkName: "InvoiceConnectorView" */ '@/views/InvoiceConnectors/CreateOrUpdate/CreateOrUpdate'),
        meta: {
          auth: true,
          middleware: auth,
          breadCrumb: 'invoiceConnector.header.create',
          headerBackToActions: true,
          saveAction: true,
          staticPathName,
          title: 'account.header.title',
          breadCrumbHidden: false
        }
      },
      {
        path: ':id/edit',
        name: 'invoiceConnector-edit',
        component: () => import(/* webpackChunkName: "InvoiceConnectorView" */ '@/views/InvoiceConnectors/CreateOrUpdate/CreateOrUpdate'),
        meta: {
          auth: true,
          middleware: auth,
          headerBackToActions: true,
          breadCrumbDisable: true,
          saveAction: true,
          staticPathName,
          title: 'account.header.title'
        }
      },
      {
        path: ':id',
        name: 'invoiceConnector-show',
        component: () => import(/* webpackChunkName: "InvoiceConnectorView" */ '@/views/InvoiceConnectors/Show/InvoiceConnector'),
        meta: {
          editableAction: true,
          customEdit: true,
          auth: true,
          middleware: auth,
          breadCrumbDisable: true,
          headerBackToActions: true,
          backToPath: '/invoice-connectors',
          staticPathName,
          title: 'account.header.title'
        }
      }
    ]
  }
]


