<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="flex justify-center">
        <BaseLogo />
      </div>
      <h2 class="mt-6 text-center text-3xl font-linter text-gray-900">
        {{ $t('account.signInToYourAccount') }}
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="handleSubmit">
          <div>
            <FormInput
                label="Email address"
                inputName="email"
                type="email"
                :value="form.email"
                :errorMessage="formErrors.email"
                @handleInputChange="handleInputChange"
            />
          </div>
          <div>
            <FormInput
                label="Password"
                type="password"
                inputName="password"
                :value="form.password"
                :errorMessage="formErrors.password"
                @handleInputChange="handleInputChange"
            />
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  v-model="form.remember"
                  class="h-4 w-4 text-gray-600 focus:ring-0	rounded">
              <label for="remember_me" class="ml-2 block text-sm text-gray-900 cursor-pointer">
                {{ $t('account.rememberMe') }}
              </label>
            </div>

            <div class="text-sm">
              <router-link to="/password/forgot" class="font-medium text-primary">
                {{ $t('account.forgotYourPassword') }}
              </router-link>
            </div>
          </div>
          <div>
            <button type="submit"
                    class="disabled:opacity-50  w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary focus:outline-none  hover:opacity-50">
              {{ $t('account.signIn') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { mapActions, mapGetters } from 'vuex';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import BaseLogo from '@/components/BaseLogo/BaseLogo';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';

export default {
  name: 'Login',
  components: {
    BaseLogo,
    ErrorMessage,
    FormInput,
    MainButton
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember: false
      },
      formErrors: {
        password: [],
        email: []
      }
    }
  },
  computed: {
    ...mapGetters([
        'loginResponseMessage',
        'userLoginError'
    ])
  },
  async beforeCreate() {
    const token = await localStorage.getItem('token');
    if (token) {
      await this.appLayoutGetAccount();
      await this.$router.push({ path: '/home' });
    }
  },
  watch: {
    userLoginError(newValue) {
      if (newValue) {
        const { errors, message } = this.loginResponseMessage;
        this.formErrors = {
          ...this.formErrors,
          ...errors
        };
        this.toastMessage(message, 'error');
      }
    }
  },
  methods: {
    ...mapActions({
      handleUserLogin: 'userLogin',
      appLayoutGetAccount: 'getAccount'
    }),
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
      this.formValidation = true;
    },
    handleSubmit() {
      this.handleUserLogin(this.form);
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  },
  destroyed() {
    this.$toast.clear();
    this.$destroy();
  }
}
</script>

<style scoped>

</style>
