import auth from '@/router/middlewares/auth';

const staticPathName = 'calendar';

export default [
    {
        path: '/calendar',
        component: () => import('@/views/Calendar/Calendar'),
        meta: {
            breadCrumb: 'calendar.header.breadCrumb',
            headerBackToActions: false,
            headerSyncCalendarEventsAction: true,
            auth: true,
            middleware: auth,
            staticPathName,
            title: 'account.header.title'
        }
    }
]
