import { getInstance } from '@/configs/axios';

/**
 *
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchDeleteCheckListItem = (id) => getInstance().delete('checklist/item', { data: { id } });

/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchCreateCheckListItem = (form) => getInstance().post('checklist/item', { ...form });

/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUpdateCheckListItem = (form) => getInstance().patch('checklist/item', { ...form });

/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUpdateCheckListItemStatus = (form) => getInstance().patch('checklist/item/status', { ...form });

/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchCreateCheckList = (form) => getInstance().post('checklist', { ...form });

/**
 *
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchDeleteCheckList = (id) => getInstance().delete('checklist', { data: { id } });

/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUpdateCheckList = (form) => getInstance().patch('checklist', { ...form });
