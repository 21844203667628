import {
    fetchCreateInvoiceLine,
    fetchDeleteInvoiceLine
} from '@/services/api/invoiceLine';

const initialState = {
    invoiceLine: {},
    updateInvoiceLineSuccess: false,
    updateInvoiceLineError: false,
    invoiceLineResponseMessage: ''
};

export default {
    state: initialState,
    getters: {
        invoiceLine: state => state.invoiceLine,
        updateInvoiceLineSuccess: state => state.updateInvoiceLineSuccess,
        updateInvoiceLineError: state => state.updateInvoiceLineError,
        invoiceLineResponseMessage: state => state.invoiceLineResponseMessage
    },
    mutations: {
        setInitialState(state) {
            state.updateInvoiceLineSuccess = false;
            state.updateInvoiceLineError = false;
            state.invoiceLineResponseMessage = '';
        },
        setInvoiceLine(state, { data, message }) {
            state.invoiceLineResponseMessage = message;
            state.updateInvoiceLineSuccess = true;
            state.invoiceLine = data;
            state.updateInvoiceLineError = false;
        },
        setInvoiceLineError(state, data) {
            state.updateInvoiceLineSuccess = false;
            state.updateInvoiceLineError = true;
            state.invoiceLineResponseMessage = data;
        }
    },
    actions: {
        async fetchCreateInvoiceLine({ commit }, { id, form }) {
            commit('setInitialState');
            const { status, data } = await fetchCreateInvoiceLine(id, form);
            status === 200 ? await commit('setInvoiceLine', data) : await commit('setInvoiceLineError', data);
        },
        async fetchDeleteInvoiceLine({ commit }, id) {
            return await fetchDeleteInvoiceLine(id);
        }
    }
}
