import menu from './menu.json';
import global from './global.json';
import product from './product.json';
import account from './account.json';
import customer from './customer.json';
import deleteAttachmentModal from './deleteAttachmentModal.json';
import deleteShareModal from './deleteShareModal.json';
import board from './board.json';
import task from './task.json';
import checkList from './checkList.json';
import invoice from './invoice.json';
import myTask from './myTask.json';
import calendar from './calendar.json';
import label from './label.json';
import invoiceConnector from './invoiceConnector.json';
import table from './table.json';
import apartment from './apartment.json';
import dataTableSearch from './dataTableSearch.json';
import dropDown from './dropDown.json';
import labelTask from './labelTask.json';
import layout from './layout.json';
import link from './link.json';
import main from './main.json';
import commentBox from './commentBox.json';
import pagination from './pagination.json';
import notFound from './notFound.json';

export default {
    menu,
    global,
    product,
    account,
    customer,
    deleteAttachmentModal,
    deleteShareModal,
    board,
    task,
    checkList,
    invoice,
    myTask,
    calendar,
    label,
    invoiceConnector,
    table,
    apartment,
    dataTableSearch,
    dropDown,
    labelTask,
    layout,
    link,
    main,
    commentBox,
    pagination,
    notFound
}
