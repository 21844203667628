import axios from 'axios';
import { getToken, unsetToken } from '@/configs/axios/token';

/**
 * @returns {AxiosInstance}
 */
export const getInstance = () => {
    const apiToken = getToken();

    const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
             Authorization: apiToken ? `Bearer ${apiToken}` : null
        },
        'default': {
            withCredentials: true
        }
    });

     axiosInstance.interceptors.response.use((r) => r, async ({ response }) => {
        if (response.status === 401) {
            unsetToken();
            return response;
        }
         return response;
    });

    return axiosInstance;
}

/**
 * @returns {AxiosInstance}
 */
export const getInstanceGoogleCalendar = (accessToken) => {

    const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_GOOGLE_CALENDAR_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization:`Bearer ${accessToken}`
        }
    });
    axiosInstance.interceptors.response.use((r) => r, async ({ response }) => {
        return response;
    });

    return axiosInstance;
}

export const CsrfInstance = axios.create({
    baseURL: process.env.VUE_APP_API_CSRF,
    headers: {
        'Content-Type': 'application/json'
    }
});
