import Vue from 'vue';
import moment from 'moment';

Vue.filter('capitalize', value => value.charAt(0).toUpperCase() + value.slice(1));

Vue.filter('getNameAbbr', value => {
    if (value === undefined || value === null) return '?';
    return value.split(/[\s-]+/, 2).map(item => item[0]).join('');
});

Vue.filter('getCommentAvatar', user => {
    if (user !== null) return user.avatar != null ? user.avatar : require('@/assets/img/account-icon.svg');
});

Vue.filter('getCommentAuthor', user => user != null ? user.name : this.$t('global.deletedUser'));

Vue.filter('getAssigneeName', assignee => assignee !== null ? assignee : '');

Vue.filter('parseDate', date => date && moment(date).lang(localStorage.getItem('locale')).format('YYYY MMMM DD'));

Vue.filter('parseBoardDate', date => {
    if (localStorage.getItem('locale') === 'fi') {
        return date && moment(date).locale(localStorage.getItem('locale')).format('D MMMM')
    }
        return moment(date).locale(localStorage.getItem('locale')).format('DD MMMM')

})

Vue.filter('parseInvoiceSentAtDate', date => {
    if (localStorage.getItem('locale') === 'fi') {
        return date && moment(date).locale(localStorage.getItem('locale')).format('YYYY MMMM D')
    }
    return moment(date).locale(localStorage.getItem('locale')).format('YYYY MMMM DD')

})

Vue.filter('parseDateMain', date => {
    if (localStorage.getItem('locale') === 'fi') {
        return date ? date && moment(date).locale(localStorage.getItem('locale')).format('D.M.YYYY') : ''
    }
    return date ? moment(date).locale(localStorage.getItem('locale')).format('YYYY-MM-DD') : ''

})

Vue.filter('getTaskMembers', data => data.length ? data.filter(member => member.title) : '');
