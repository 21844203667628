import { getInstance } from '@/configs/axios';


/**
 *
 * @returns {Promise<label[]>}
 */
export const fetchLabels = async () => {
    const { data } = await getInstance().get('label/get-all');

    return data;
}

/**
 *
 * @returns {Promise<any>}
 */
export const fetchLabelsTasks = async (labelId) => {
    const { data } = await getInstance().get(`label/${labelId}/tasks`);
    return data;
}

/**
 *
 * @param id
 * @returns {Promise<Label>}
 */
export const fetchLabel = async (id) => {
    const { data } = await getInstance().get('label/' + id);

    return data;
}

/**
 *
 * @param {CreateLabel}form
 * @returns {Promise<any>}
 */
export const createLabel = async (form) => {
    return await getInstance().post('label', form);
}

/**
 *
 * @param id
 * @param {UpdateLabel}form
 * @returns {Promise<any>}
 */
export const updateLabel = async (id, form) => await getInstance().put('label/' + id, form);

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const deleteLabel = async (id) => {
    const { data } = await getInstance().delete('label/'+id);

    return data;
}
