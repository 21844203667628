import { getAssigneeUsers } from '@/services/api/assigneeUsers';

export default {
    state: {
        assigneeUsers: []
    },
    getters: {
        assigneeUsers: state => state.assigneeUsers
    },
    mutations: {
        setAssigneeUsers(state, { data }) {
            state.assigneeUsers = data;
        }
    },
    actions: {
        async getAssigneeUsers({ commit }) {
            commit('setAssigneeUsers', await getAssigneeUsers());
        }
    }
}
