import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales';

Vue.use(VueI18n);

const messages = { ...locales };

export default new VueI18n({
  locale: localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: localStorage.getItem('locale') || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: messages
});
