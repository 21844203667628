import { fetchVendors } from '@/services/api/vendors';

/**
 *
 * @type {{invoiceConnectors: vendor[], vendors: Vendors}}
 */
const initialState = {
  vendors: []
};

export default {
  state: initialState,
  getters: {
    vendors: state => state.vendors
  },
  mutations: {
    setVendors(state, payload) {
      state.vendors = payload;
    }
  },
  actions: {
      async getVendors({ commit }) {
       commit('setVendors', await fetchVendors());
    }
  }
};
