import { getInstance } from '@/configs/axios';

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchCreateInvoiceLine = async (id, form) =>
    await getInstance().post(`checklist/item/${id}/invoice-line`, { ...form })

export const fetchDeleteInvoiceLine = async (id) => {
    const { data } = await getInstance().delete('invoice-line', { data: { id } });

    return data;
}
