<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-linter text-gray-900">
        {{ $t('account.resetPasswords') }}
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="mt-8 space-y-6" >
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <FormInput
                  label="Email address"
                  inputName="email"
                  type="email"
                  :value="form.email"
                  :errorMessage="formErrors.email"
                  @handleInputChange="handleInputChange"
              />
            </div>
          </div>
          <div class="flex justify-between">
            <router-link to="/login" >
              <MainButton
                  name="Go Back"
              />
            </router-link>
            <MainButton
                :disabled="!(!!form.email)"
                name="Reset Password"
                @clickAction="resetPassword"
            />
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ForgotPassword',
  components: {
    MainButton,
    FormInput
  },
  data() {
    return {
      form: {
        email: ''
      },
      formErrors: {
        email: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'resetPasswordSuccess',
      'resetPasswordError',
      'resetPasswordResponseMessage'
    ])
  },
  watch: {
    resetPasswordSuccess(newValue) {
      newValue && this.toastMessage(this.resetPasswordResponseMessage, 'success');
    },
    resetPasswordError(newValue) {
      newValue && this.handleRequestErrorMessage(newValue);
    }
  },
  methods: {
    ...mapActions({
      handleResetPassword: 'resetPassword'
    }),
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form.email = value
    },
    resetPassword() {
      this.handleResetPassword({ email: this.form.email });
    },
    handleRequestErrorMessage(isError) {
      if (isError) {
        const { message, errors } = this.resetPasswordResponseMessage;
        this.formErrors = { ...this.formErrors, ...errors };
        this.toastMessage(message, 'error');
      }
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style scoped>
.float-right{
  float: left;
  text-align: center;
  color: #5f707e;
}
</style>
