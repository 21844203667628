import {
    fetchTask,
    attachMember,
    updateTask,
    detachMember,
    createTask,
    deleteTask,
    fetchCreateLink,
    fetchUpdateLink,
    fetchDeleteLink,
    fetchInvoiceTransmit,
    fetchInvoiceTransmitAll,
    attachLabel,
    detachLabel
} from '@/services/api/task';

const initialState = {
    task: {},
    updateTaskSuccess: false,
    updateTaskError: false,
    attachMemberSuccess: false,
    attachMemberError: false,
    attachLabelSuccess: false,
    attachLabelError: false,
    detachMemberSuccess: false,
    detachMemberError: false,
    createTaskSuccess: false,
    createTaskError: false,
    taskResponseMessage: '',
    addInvoiceSuccess: false,
    addInvoiceError: false,
    addInvoiceResponseMessage: '',
    detachLabelSuccess: false,
    detachLabelError: false
};

export default {
    state: initialState,
    getters: {
        task: state => state.task,
        updateTaskSuccess: state => state.updateTaskSuccess,
        updateTaskError: state => state.updateTaskError,
        createTaskSuccess: state => state.createTaskSuccess,
        createTaskError: state => state.createTaskError,
        taskResponseMessage: state => state.taskResponseMessage,
        addInvoiceSuccess: state => state.addInvoiceSuccess,
        addInvoiceError: state => state.addInvoiceError,
        addInvoiceResponseMessage: state => state.addInvoiceResponseMessage
    },
    mutations: {
        setTask(state, { data }) {
            state.task = data;
        },
        setInitialState(state) {
            state.attachMemberSuccess = false;
            state.attachMemberError = false;
            state.detachMemberSuccess = false;
            state.detachMemberError = false;
            state.attachLabelSuccess = false,
            state.attachLabelError = false,
            state.detachLabelSuccess = false,
            state.detachLabelError = false,
            state.taskResponseMessage = '';
        },
        setUpdateTaskInitialState(state) {
            state.updateTaskSuccess = false;
            state.updateTaskError = false;
        },
        setCreateTaskInitialState(state) {
            state.createTaskSuccess = false;
            state.createTaskError = false;
        },
        setUpdateTaskSuccess(state, { data, message }) {
            state.task = data;
            state.updateTaskSuccess = true;
            state.taskResponseMessage = message;
        },
        setUpdateTaskError(state, message) {
            state.updateTaskSuccess = false;
            state.updateTaskError = true;
            state.taskResponseMessage = message;
        },
        setCreateTaskSuccess(state, { data, message }) {
            state.task = data;
            state.createTaskSuccess = true;
            state.createTaskError = false;
            state.taskResponseMessage = message;
        },
        setCreateTaskError(state, message) {
            state.createTaskSuccess = false;
            state.createTaskError = true;
            state.taskResponseMessage = message;
        },
        setAttachMemberSuccess(state, message) {
            state.attachMemberSuccess = true;
            state.taskResponseMessage = message;
        },
        setAttachLabelSuccess(state, message) {
            state.attachLabelSuccess = true;
            state.taskResponseMessage = message;
        },
        setAttachMemberError(state, message) {
            state.attachMemberSuccess = false;
            state.attachMemberError = true;
            state.taskResponseMessage = message;
        },
        setAttachLabelError(state, message) {
            state.attachLabelSuccess = false;
            state.attachLabelError = true;
            state.taskResponseMessage = message;
        },
        setDetachMemberSuccess(state, message) {
            state.detachMemberSuccess = true;
            state.detachMemberError = false;
            state.taskResponseMessage = message;
        },
        setDetachMemberError(state, message) {
            state.detachMemberSuccess = false;
            state.detachMemberError = true;
            state.taskResponseMessage = message;
        },
        setDetachLabelSuccess(state, message) {
            state.detacLabelSuccess = true;
            state.detachLabelError = false;
            state.taskResponseMessage = message;
        },
        setDetachLabelError(state, message) {
            state.detachLabelSuccess = false;
            state.detachLabelError = true;
            state.taskResponseMessage = message;
        },
        setInvoicesInitialState(state) {
            state.addInvoiceSuccess = false;
            state.addInvoiceError = false;
            state.addInvoiceResponseMessage = '';
        },
        setTaskInvoicesSuccess(state, { data, message }) {
            state.task = data;
            state.addInvoiceSuccess = true;
            state.addInvoiceError = false;
            state.addInvoiceResponseMessage = message;
        },
        setTaskInvoicesError(state, { message }) {
            state.addInvoiceSuccess = false;
            state.addInvoiceError = true;
            state.addInvoiceResponseMessage = message;
        }
    },
    actions: {
        async fetchTask({ commit }, id) {
            commit('setTask', await fetchTask(id));
        },
        async updateTask({ commit }, { id, form }) {
            await commit('setUpdateTaskInitialState');
            const response = await updateTask(id, form);
            response.status === 200 ? await commit('setUpdateTaskSuccess', response.data)
                : await commit('setUpdateTaskError', response.data);
        },
        deleteTask({ commit }, id) {
            return deleteTask(id);
        },
        async attachMember({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await attachMember(id, form);
            response.status === 200 ? await commit('setAttachMemberSuccess') : await commit('setAttachMemberError');
        },
        async addLink({ commit }, { id, form }){
            await commit('setInitialState')
            commit('setTask', await fetchCreateLink(id, form));
        },
        async updateLink({ commit }, { id, link }){
            await commit('setInitialState')
            commit('setTask', await fetchUpdateLink(id, link));
        },
        async deleteLink({ commit }, { id, linkId }){
            await commit('setInitialState')
            commit('setTask', await fetchDeleteLink(id, linkId));
        },
        async detachMember({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await detachMember(id, form);
            response.status === 200 ? await commit('setDetachMemberSuccess') : await commit('setDetachMemberError');
        },
        async fetchAttachLabel({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await attachLabel(id, form);
            response.status === 200 ? await commit('setAttachLabelSuccess') : await commit('setAttachLabelError');
        },
        async fetchDetachLabel({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await detachLabel(id, form);
            response.status === 200 ? await commit('setDetachLabelSuccess') : await commit('setDetachLabelError');
        },
        async createTask({ commit }, { form }) {
            await commit('setCreateTaskInitialState');
            const response = await createTask(form);
            response.status === 201 ? await commit('setCreateTaskSuccess', response.data)
                : await commit('setCreateTaskError', response.data);
        },
        async fetchInvoiceTransmit({ commit }, { id }) {
            commit('setInvoicesInitialState');
            const { status, data } = await fetchInvoiceTransmit(id);
            status === 200 ? commit('setTaskInvoicesSuccess', data) : commit('setTaskInvoicesError', data);
        },
        async fetchInvoiceTransmitAll() {
            return fetchInvoiceTransmitAll();
        }
    }
};
