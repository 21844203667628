import auth from '@/router/middlewares/auth';

const staticPathName = 'products';

export default [
    {
        path: '/products',
        component: () => import(/* webpackChunkName: "ProductView"*/ '@/views/Products/Products'),
        meta: {
            breadCrumb: 'product.header.table'
        },
        children: [
            {
                path: '/',
                name: 'product',
                component: () => import(/* webpackChunkName: "ProductView" */ '@/views/Products/ProductsTable/ProductsTable'),
                meta: {
                    headerBackToActions: false,
                    headerAddNewAction: true,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title'
                }
            }
        ]
    },
    {
        path: '/product',
        component: () => import(/* webpackChunkName: "ProductView"*/ '@/views/Products/Products'),
        meta: {
            breadCrumb: 'product.header.table'
        },
        children: [
            {
                path: 'create',
                name: 'product-create',
                component: () => import(/* webpackChunkName: "ProductView" */ '@/views/Products/CreateOrUpdate/CreateOrUpdate'),
                meta: {
                    auth: true,
                    middleware: auth,
                    breadCrumb: 'product.header.create',
                    headerBackToActions: true,
                    saveAction: true,
                    staticPathName,
                    title: 'account.header.title'
                }
            },
            {
                path: ':id/edit',
                name: 'product-edit',
                component: () => import(/* webpackChunkName: "ProductView" */ '@/views/Products/CreateOrUpdate/CreateOrUpdate'),
                meta: {
                    auth: true,
                    middleware: auth,
                    headerBackToActions: true,
                    breadCrumbDisable: true,
                    saveAction: true,
                    staticPathName,
                    title: 'account.header.title'
                }
            },
            {
                path: ':id',
                name: 'product-show',
                component: () => import(/* webpackChunkName: "ProductView" */ '@/views/Products/Show/Product'),
                meta: {
                    editableAction: true,
                    auth: true,
                    middleware: auth,
                    breadCrumbDisable: true,
                    headerBackToActions: true,
                    backToPath: '/products',
                    staticPathName,
                    title: 'account.header.title'
                }
            }
        ]
    }
]
