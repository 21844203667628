import { getInstance } from '@/configs/axios';


/**
 *
 * @returns {Promise<user[]>}
 */
export const fetchUsers = async () => {
    const { data } = await getInstance().get('user/get-all');

    return data;
}

/**
 *
 * @returns {Promise<any>}
 */
export const fetchUsersWithoutPagination = async () => {
    const { data } = await getInstance().get('user/without-pagination');

    return data;
}

/**
 *
 * @param id
 * @returns {Promise<User>}
 */
export const fetchUser = async (id) => {
    const { data } = await getInstance().get('user/' + id);

    return data;
}

/**
 *
 * @param {CreateUser}form
 * @returns {Promise<any>}
 */
export const createUser = async (form) => {
    return await getInstance().post('user', form);
}

/**
 *
 * @param id
 * @param {UpdateUser}form
 * @returns {Promise<any>}
 */
export const updateUser = async (id, form) => await getInstance().post('user/' + id, form);

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const deleteUser = async (id) => {
    const { data } = await getInstance().delete('user/'+id);

    return data;
}
