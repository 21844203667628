import {
    deleteInvoice,
    fetchInvoice,
    fetchInvoices,
    fetchSelectedInvoices,
    fetchSendInvoice
} from '@/services/api/invoice';

const initialState = {
  invoices: [],
  invoice: {},
  selectInvoiceType:'none'
};

export default {
    state: initialState,
    getters: {
        invoices: state => state.invoices,
        invoice: state => state.invoice,
        selectInvoiceType: state => state.selectInvoiceType
    },
    mutations: {
        setInvoices(state, data) {
            state.invoices = data;
        },
        setInvoice(state, { data }) {
            state.invoice = data.data;
        },
        setSelectInvoiceType(state, type) {

            state.selectInvoiceType = type
        }
    },
    actions: {
        async fetchInvoice({ commit }, id) {
            commit('setInvoice', await fetchInvoice(id));
        },
        async fetchInvoices({ commit }, form) {
            commit('setInvoices', await fetchInvoices(form.page, form.search, form.sortBy, form.sortDesc));
        },
        async fetchSelectedInvoices({ commit }, form) {
          return await fetchSelectedInvoices(form);
        },
        async fetchSendInvoice({ commit }, id) {
            return await fetchSendInvoice(id);
        },
        setSelectInvoiceType({ commit }, type){
            commit('setSelectInvoiceType', type)
        },
        async fetchDeleteInvoice(context, id) {
            return await deleteInvoice(id);
        }
    }
}
