import auth from '@/router/middlewares/auth';

const staticPathName = 'invoices';

export default [
    {
        path: '/invoices',
        component: () => import(/* webpackChunkName: "InvoiceView"*/ '@/views/Invoices/Invoices'),
        meta: {
            breadCrumb: 'invoice.header.table'
        },
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "InvoiceView"*/ '@/views/Invoices/InvoicesTable/InvoicesTable'),
                name: 'invoices',
                meta: {
                    headerIsSendInvoice: true,
                    headerBackToActions: false,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title'
                }
            },
            {
                path: 'summary',
                component: () => import(/* webpackChunkName: "InvoiceView"*/ '@/views/Invoices/InvoicesSummaryTable/InvoicesSummaryTable'),
                meta: {
                    headerIsSendInvoice: false,
                    headerBackToActions: false,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title',
                    breadCrumbHidden: false
                }
            }
        ]
    },
    {
        path: '/invoice',
        component: () => import(/* webpackChunkName: "InvoiceView"*/ '@/views/Invoices/Invoices'),
        meta: {
            breadCrumb: 'invoice.header.table'
        },
        children: [
            {
                path: ':id',
                name: 'invoice-show',
                component: () => import(/* webpackChunkName: "InvoiceView"*/ '@/views/Invoices/Show/Invoice'),
                meta: {
                    headerBackToActions: true,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title',
                    breadcrumbDisabled: true
                }
            }
        ]
    }
];
