import { fetchLabelsTasks } from '../../services/api/label';

const initialState = {
    labelsTasks: {}
};

export default {
    state: initialState,
    getters: {
        labelsTasks: state => state.labelsTasks
    },
    mutations: {
        setLabelsTasks(state, { data }) {
            state.labelsTasks = data;
        }
    },
    actions: {
        async fetchLabelsTasks({ commit }, labelId) {
            commit('setLabelsTasks', await fetchLabelsTasks(labelId));
        }
    }
}
