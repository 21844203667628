import auth from '@/router/middlewares/auth';

const staticPathName = 'customers';

export default [
    {
        path: '/customers',
        component: () => import(/* webpackChunkName: "CustomerView" */ '@/views/Customers/Customers'),
        meta: {
            breadCrumb: 'customer.header.table'
        },
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "CustomerView" */ '@/views/Customers/CustomersTable/CustomersTable'),
                name: 'customer',
                meta: {
                    headerBackToActions: false,
                    headerAddNewAction: true,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title'
                }
            }
        ]
    },
    {
        path: '/customer',
        component: () => import(/* webpackChunkName: "CustomerView" */ '@/views/Customers/Customers'),
        meta: {
            breadCrumb: 'customer.header.table'
        },
        children: [
            {
                path: 'create',
                name: 'customer-create',
                component: () => import(/* webpackChunkName: "CustomerView" */ '@/views/Customers/CreateOrUpdate/CreateOrUpdate'),
                meta: {
                    auth: true,
                    middleware: auth,
                    breadCrumb: 'customer.header.create',
                    headerBackToActions: true,
                    saveAction: true,
                    staticPathName,
                    title: 'account.header.title'
                }
            },
            {
                path: ':id/edit',
                name: 'customer-edit',
                component: () => import(/* webpackChunkName: "CustomerView" */ '@/views/Customers/CreateOrUpdate/CreateOrUpdate'),
                meta: {
                    auth: true,
                    middleware: auth,
                    headerBackToActions: true,
                    breadCrumbDisable: true,
                    saveAction: true,
                    staticPathName,
                    title: 'account.header.title'
                }
            },
            {
                path: ':id',
                name: 'customer-show',
                component: () => import(/* webpackChunkName: "CustomerView" */ '@/views/Customers/Show/Customer'),
                meta: {
                    editableAction: true,
                    auth: true,
                    middleware: auth,
                    breadCrumbDisable: true,
                    headerBackToActions: true,
                    backToPath: '/customers',
                    staticPathName,
                    title: 'account.header.title'
                }
            }
        ]
    }
]
