import auth from '@/router/middlewares/auth';

export default [
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "AccountView"*/ '@/views/Account/AccountSettings'),
        meta: {
            headerBackToActions: true,
            breadCrumb: 'account.header.title',
            title: 'account.header.title',
            auth: true,
            middleware: auth,
            editableAction: true
        }
    }
]
