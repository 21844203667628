import { fetchMyTasks, fetchMyBoards } from '@/services/api/myTask';

const initialState = {
    myListingsTasks: []
};

export default {
    state: initialState,
    getters: {
        myListingsTasks: state => state.myListingsTasks
    },
    mutations: {
        setMyListingsTasks(state, { data }) {
            state.myListingsTasks = data;
        }
    },
    actions: {
        async fetchMyTasks({ commit }, id) {
            commit('setMyListingsTasks', await fetchMyTasks(id));
        },
        async fetchMyBoards({ commit }) {
            commit('setBoardsWithTasks', await fetchMyBoards());
        }
    }
}
