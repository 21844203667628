import {
    fetchCreateCheckList,
    fetchDeleteCheckList,
    fetchDeleteCheckListItem,
    fetchCreateCheckListItem,
    fetchUpdateCheckList,
    fetchUpdateCheckListItem,
    fetchUpdateCheckListItemStatus
} from '@/services/api/checkList';

const initialState = {

};

export default {
    state: initialState,
    getters: {

    },
    mutations: {

    },
    actions: {
        fetchCreateCheckList({ commit }, { form }) {
            return fetchCreateCheckList(form);
        },
        fetchDeleteCheckList({ commit }, { id }) {
            return fetchDeleteCheckList(id);
        },
        fetchUpdateCheckList({ commit }, { form }) {
            return fetchUpdateCheckList(form);
        },
        fetchCreateCheckListItem({ commit }, { form }) {
            return fetchCreateCheckListItem(form);
        },
        fetchDeleteCheckListItem({ commit }, { id }) {
            return fetchDeleteCheckListItem(id);
        },
        fetchUpdateCheckListItem({ commit }, { form }) {
            return fetchUpdateCheckListItem(form);
        },
        fetchUpdateCheckListItemStatus({ commit }, { form }) {
            return fetchUpdateCheckListItemStatus(form);
        }
    }
}
