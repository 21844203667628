import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/auth/login';
import resetPassword from './modules/auth/resetPassword';
import products from './modules/product.js';
import account from './modules/account.js';
import customer from './modules/customer.js';
import assigneeUsers from './modules/assigneeUsers.js';
import sharedSpace from './modules/sharedSpace.js';
import breadCrumbs from './modules/breadCrumb.js';
import comment from './modules/comment.js';
import attachmentFile from './modules/attachmentFile.js';
import board from './modules/board.js';
import task from './modules/task.js';
import publicPages from './modules/publicBoards';
import checkList from './modules/checkList';
import invoiceLine from './modules/invoiceLine';
import invoice from './modules/invoice';
import myTask from './modules/myTask';
import calendar from './modules/calendar';
import user from './modules/user';
import label from './modules/label';
import labelsTasks from './modules/labelsTasks';
import invoiceConnector from './modules/invoiceConnector';
import invoiceSummary from './modules/invoiceSummary';
import vendor from './modules/vendor';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		login,
		products,
		account,
		customer,
		assigneeUsers,
		sharedSpace,
		breadCrumbs,
		comment,
		resetPassword,
		attachmentFile,
		board,
		task,
		publicPages,
		checkList,
		invoiceLine,
		invoice,
		myTask,
		calendar,
		user,
		label,
		labelsTasks,
		invoiceConnector,
		vendor,
		invoiceSummary
	}
})
