import Vue from 'vue';
import App from './App.vue';
import store from './store/index';
import router from './router/index';
import toast from './components/Modals/Toasts/index';
import './filters';
import './sass/app.scss';
import i18n from './i18n';
import Trend from 'vuetrend';
import VTooltip from 'v-tooltip'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)
Vue.use(VTooltip)

Vue.config.productionTip = false;
Vue.use(toast);
Vue.use(Trend);

new Vue({
    store,
    router,
    toast,
    i18n,
    render: h => h(App)
}).$mount('#app');
