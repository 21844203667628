import { getInstance } from '@/configs/axios';


/**
 *
 * @param id
 * @returns {Promise<*>}
 */
export const fetchTask = async (id) => {
    const { data } = await getInstance().get(`/task/${id}`);

    return data;
}

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateTask = async (id, form) => await getInstance().patch(`task/${id}`, form);

/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchCreateLink =async (id, form) => {
    const { data } = await getInstance().post(`task/${id}/addLink`, form);
    return data
}
export const fetchUpdateLink =async (id, link) => {
    const { data } = await getInstance().patch(`task/${id}/updateLink/${link.id}`, link);
    return data
}
export const fetchDeleteLink =async (id, linkId) => {
    const { data } = await getInstance().post(`task/${id}/removeLink/${linkId}`);
    return data
}
/**
 *
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createTask = async (form) => await getInstance().post('task/create', form, {
    'Content-Type': 'multipart/form-data'
});

/**+
 *
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteTask = (id) => getInstance().delete('task', { data: { id } });

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const attachMember = async (id, form) => await getInstance().patch(`task/${id}/member/attach`, form);

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const detachMember = async (id, form) => await getInstance().patch(`task/${id}/member/detach`, form);

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const attachLabel = async (id, form) => await getInstance().patch(`task/${id}/label/attach`, form);

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const detachLabel = async (id, form) => await getInstance().patch(`task/${id}/label/detach`, form);

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchInvoiceTransmit = async (id) => await getInstance().patch(`task/${id}/transmit`);

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchInvoiceTransmitAll = async () => await getInstance().post('task/transmit-all');
/**
 *
 * @param boardId
 * @param companyId
 * @param searchQuery
 * @param sortBy
 * @param sortDesc
 * @returns {Promise<any>}
 */
export const fetchTasksByCompany = async (boardId, companyId, searchQuery, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';
    const searchParams = searchQuery ? `&search=${searchQuery}` : '';

    const { data } = await getInstance().get(`/task/fetch-by-company/${boardId}/${companyId}?${searchParams}${sortParams}`);

    return data;
}
/**
 *
 * @returns {Promise<any>}
 * @param boardId
 * @param memberId
 * @param searchQuery
 * @param sortBy
 * @param sortDesc
 */
export const fetchTasksByMembers = async (boardId, memberId, searchQuery, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';
    const searchParams = searchQuery ? `&search=${searchQuery}` : ''
    const { data } = await getInstance().get(`/task/fetch-by-members/${boardId}/${memberId}?${searchParams}${sortParams}`);

    return data;
}
/**
 *
 * @param boardId
 * @param memberId
 * @param searchQuery
 * @param sortBy
 * @param sortDesc
 * @returns {Promise<any>}
 */
export const fetchTasksByMyTask = async (boardId, memberId, searchQuery, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';
    const searchParams = searchQuery ? `&search=${searchQuery}` : ''
    const { data } = await getInstance().get(`/task/fetch-by-my-task/${boardId}/${memberId}?${searchParams}${sortParams}`);

    return data;
}
