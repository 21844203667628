import { getInstance } from '@/configs/axios';

export const fetchPublicBoards = async (uuid) => {
    const { data } = await getInstance().get(`public/space/${uuid}`);

    return data;
}

export const fetchPublicListings = async (uuid, boardUuid, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `?sortBy=${sortBy}&sortDesc=${sortDesc}` : '';

    const { data } = await getInstance().get(`public/space/${uuid}/board/${boardUuid}${sortParams}`);

    return data;
}

export const fetchPublicTask = async (uuid, taskUuid) => {
    const { data } = await getInstance().get(`public/space/${uuid}/task/${taskUuid}`);

    return data;
}
