import { getInstance } from '@/configs/axios';

/**
 *
 * @returns {Promise<any>}
 */
export const fetchMyTasksListings = async (id, searchQuery = '') => {
    //const { data } = await getInstance().get(`listing/my-tasks/${id}`);
    //return data;

    const searchParams = searchQuery ? `?search=${searchQuery}` : ''
    const { data } = await getInstance().get(`board/${id}/my-tasks/listings${searchParams}`);
    return data;
}

export const fetchMyTasks = async (id, searchQuery, sortBy = null, sortDesc = null) => {
    //const { data } = await getInstance().get(`listing/my-tasks/${id}`);
    //return data;

    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';
    const searchParams = searchQuery ? `&search=${searchQuery}` : '';
    const { data } = await getInstance().get(`listing/my-tasks/${id}?${searchParams}${sortParams}`);

    return data;
}
export const fetchMyBoards = async () => {
    const { data } = await getInstance().get('board/my-boards');
    return data;
}
