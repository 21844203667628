const initialState = {
    name: '',
    items: []
};

export default {
    state: initialState,
    getters: {
        getBreadCrumb: state => state.name,
        getBreadCrumbItems: state => state.items
    },
    mutations: {
        setBreadCrumbItem(state, name) {
            state.name = name;
        },
        setBreadCrumbItems(state, data) {
            state.items[data.index] = {
                name: data.name,
                to: data.to,
                hidden: data.hidden
            };
            state.items = [...state.items];
        },
        resetBreadCrumbItems(state) {
            state.name = '';
            state.items = [];
        }
    },
    actions: {
        async setBreadCrumbItem({ commit }, name) {
            commit('setBreadCrumbItem', name);
        },
        async setBreadCrumbItems({ commit }, data) {
            commit('setBreadCrumbItems', data);
        },
        async resetBreadCrumbItems({ commit }) {
            commit('resetBreadCrumbItems');
        }
    }
}
