import { getInstance } from '@/configs/axios';

/**
 *
 * @returns {Promise<any>}
 */
export const fetchInvoices = async (page, search, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : ''
    const { data } = await getInstance().get(`invoice/get-all?page=${page}&search=${search}${sortParams}`);
    return data;
};

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const fetchInvoice = async (id) => {
    const { data } = await getInstance().get(`invoice/${id}`);

    return data;
}

/**
 *
 * @param form
 * @returns {Promise<any>}
 */
export const fetchSelectedInvoices = async (form) => {
    const { data } = await getInstance().post('invoice/send', { body: form });

    return data;
}

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const fetchSendInvoice = async (id) => {
    return await getInstance().post(`invoice/${id}/send`);
}

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const deleteInvoice = async (id) => {
    const { data } = await getInstance().delete(`/invoice/delete-drafted-invoice/${id}`);

    return data;
}
