import { getInstance, getInstanceGoogleCalendar } from '@/configs/axios';

/**
 *
 * @returns {Promise<any>}
 */
export const fetchCalendarEvents = async () => {
    const { data } = await getInstance().get('calendar/events');
    return data;
}

/**
 *
 * @param id
 * @returns {Promise<any>}
 */
export const fetchGetTasksByCustomerId = async (id) => {
    const { data } = await getInstance().get(`organization/customers/${id}/tasks`);
    return data;
};

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchEventProcess = async (id, form) => await getInstance().patch(`calendar/events/${id}/process`, { ...form });

/**
 *
 * @param id
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUpdateEventProcess = async (id, form) => await getInstance().patch(`calendar/events/${id}/process/update`, { ...form });

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncCalendarEvents = async () => await getInstance().get('calendar/sync');

/**
 *
 * @param id
 * @param status
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUpdateEventStatus = async (id, status) => await getInstance().patch(`calendar/events/${id}/ignore`, { ...status });

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userCalendarEvents = async () => await getInstance().get('calendar/check-user');

export const deleteCalendarEvents = async () => await getInstance().delete('calendar/delete-all');

export const fetchGoogleCalendarEvents = async (googleCalendarId, apiKey, accessToken) => await getInstanceGoogleCalendar(accessToken).get(`/${googleCalendarId}/events?key=${apiKey}`)

export const saveCalendarEvents = async (events) => await getInstance().post('calendar/events', events)
