import auth from '@/router/middlewares/auth';
import isadmin from '@/router/middlewares/isadmin';

export default [
    {
        path: '/labels',
        name: '',
        component: () => import(/* webpackChunkName: "AccountView"*/ '@/views/Labels/Labels'),
        meta: {
            headerBackToActions: true,
            breadCrumb: 'label.header.title',
            auth: true,
            middleware: [auth, isadmin],
            editableAction: false,
            headerAddNewAction: false,
            headerAddNewActionModal:true
        },
        children: [
            {
                path: '',
                name: 'label',
                component: () => import(/* webpackChunkName: "Label table view" */'@/views/Labels/LabelsTable/LabelsTable'),
                meta: {
                    headerBackToActions: true,
                    breadCrumb: 'label.header.title',
                    auth: true,
                    middleware: [auth, isadmin],
                    editableAction: false,
                    headerAddNewAction: false,
                    headerAddNewActionModal:true,
                    title: 'account.header.title'
                }
            }
            ]
    },
    {
        path: '/label',
        name: '',
        component: () => import(/* webpackChunkName: "AccountView"*/ '@/views/Labels/Labels'),
        meta: {
            headerBackToActions: true,
            breadCrumb: 'label.header.title',
            auth: true,
            middleware: [auth, isadmin],
            editableAction: false,
            headerAddNewAction: false,
            headerAddNewActionModal:true
        },
        children: [
            {
                path: ':id',
                name: 'label-tasks',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Labels/LabelTasks/LabelTasksTable'),
                meta: {
                    headerIsCreateTaskActions: false,
                    breadCrumbDisable: false,
                    breadCrumb: 'label.header.titleTasks',
                    auth: true,
                    middleware: auth,
                    title: 'account.header.title'
                }
            },
            {
                path: '',
                name: 'labels',
                component: () => import(/* webpackChunkName: "Label table view" */'@/views/Labels/LabelsTable/LabelsTable'),
                meta: {
                    headerBackToActions: true,
                    breadCrumb: 'label.header.title',
                    auth: true,
                    middleware: [auth, isadmin],
                    editableAction: false,
                    headerAddNewAction: false,
                    headerAddNewActionModal:true,
                    title: 'account.header.title'
                }
            }
        ]
    }
]
