import { getInstance } from '@/configs/axios';

/**
 *
 * @param prefix
 * @param ownerId
 * @param id
 * @returns {Promise<any>}
 */
export const deleteAttachmentFile = async (prefix, ownerId, id) => await getInstance().delete(`${prefix}/${ownerId}/attachment`, { data: { id } });

/**
 *
 * @param prefix
 * @param ownerId
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteGoogleAttachmentFile = async (prefix, ownerId, id) => await getInstance().delete(`${prefix}/${ownerId}/google-attachment`, { data: { id } });

/**
 *
 * @param prefix
 * @param ownerId
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createAttachmentFile = async (prefix, ownerId, form) => {
    return await getInstance().post(`${prefix}/${ownerId}/attachment`, form, {
        'Content-Type': 'multipart/form-data'
    });
}

/**
 *
 * @param prefix
 * @param ownerId
 * @param form
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createGoogleAttachmentFile = async (prefix, ownerId, form) => await getInstance().post(`${prefix}/${ownerId}/google-attachment`, { ...form });
