<template>
  <div class="flex flex-col items-start justify-start gap-2">
    <label v-if="label" class="text-sm font-medium text-gray-500">{{ label }}</label>
    <div class="w-full relative rounded-md">
      <input
          :type="type"
          :name="inputName"
          @blur="handleBlurChange"
          :id="inputName"
          :value="value"
          :placeholder="placeholder"
          @input="handleOnInput"
          ref="input"
          @keyup="$emit('handleKeyUp', $event, inputName)"
          :disabled="disabled"
          :class="['h-formInput py-2 sm:text-sm rounded-md focus:outline-none focus:ring-opacity-0 block w-full', {
'border-red-300 focus:border-red-300 focus:ring-gray-300 focus:border-gray-300  text-red-900 placeholder-red-300': errorMessage.length,
    'focus:ring-gray-300 focus:border-gray-300 sm:text-sm border-gray-300': !errorMessage.length,
    'mt-1 cursor-not-allowed border-white bg-gray-100': disabled,
    'px-3':classInputs,
    'pl-3 pr-10':!classInputs
}]"
      >
    </div>
    <ErrorMessage v-if="errorMessage.length && errorMessage[0]" :messages="[errorMessage[0]]"/>
  </div>
</template>

<script>
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage'

export default {
  name: 'FormInput',
  components: {
    ErrorMessage
  },
  props: {
    label: {
      type: String,
      'default': ''
    },
    errorMessage: {
      type: Array,
      'default': Array
    },
    inputName: String,
    value: String | Number,
    type: {
      type: String,
      'default': 'text'
    },
    disabled: {
      type: Boolean,
      'default': false
    },
    placeholder: {
      type: String,
      'default': ''
    },
    autofocus: {
      type: Boolean,
      'default': false
    },
    classInputs: {
      type: Boolean,
      'default': false
    }
  },
  mounted() {
    this.autofocus && this.$refs.input.focus();
  },
  methods: {
    handleOnInput(event) {
      this.$emit('handleInputChange', this.inputName, event.target.value);
    },
    handleBlurChange(event) {
      this.$emit('handleBlurChange', this.inputName, event.target.value);
    }
  }
}
</script>

<style scoped>

</style>
