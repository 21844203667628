<template>
  <router-link to="/boards">
    <MainLogo />
  </router-link>
</template>

<script>
import MainLogo from '@/components/Icons/MainLogo';

export default {
  name: 'BaseLogo',
  components: { MainLogo }
}
</script>

<style scoped>

</style>
