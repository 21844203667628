<template>
  <button
      @click="$emit('clickAction')"
      :type="type"
      :class="['disabled:opacity-50 inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md truncate text-white focus:outline-none', {
               'bg-primary hover:opacity-50': bgStyle === 'primary',
               'border-gray-300 text-gray-700 bg-white hover:bg-gray-50  focus:ring-gray-700  '  : bgStyle === 'light'}
      ]"
      :disabled="disabled">
    {{name}}
  </button>
</template>

<script>
export default {
  name: 'MainButton',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      'default': 'button'
    },
    bgStyle: {
      type: String,
      'default': 'primary'
    },
    disabled: {
      type: Boolean,
      'default': false
    }
  }
}
</script>

<style scoped>
.mainButton:disabled{
  opacity: 0.5;
}
</style>
