import { fetchRefreshToken, fetchUserLogin, fetchUserLogOut } from '@/services/api/auth/login';
import { setAuthorizedUserCredentials, setAuthorizedUserRefreshTokenSuccess, unsetToken } from '@/configs/axios/token';
import { fetchAccount } from '@/services/api/account';

/**
 *
 * @type {LoginInitialState}
 */
const initialState = {
    userLoginSuccess: false,
    userLoginError: false,
    userLoginErrorMessage: []
};


export default {
    namespace:true,
    state: initialState,
    getters: {
        loginResponseMessage: state => state.userLoginErrorMessage,
        userLoginError: state => state.userLoginError
    },
    mutations:{
        setInitialState(state) {
            state.userLoginSuccess = false;
            state.userLoginError = false;
            state.userLoginErrorMessage = [];
        },
        async setUserLoginSuccess(state, { tokenExpiredDate, token, remember }) {
            state.userLoginSuccess = true;
            state.userLoginError = false;
            await setAuthorizedUserCredentials(token, tokenExpiredDate, remember);
        },
        setUserLoginError(state, payload) {
            state.userLoginSuccess = false;
            state.userLoginError = true;
            state.userLoginErrorMessage = payload;
        },
        async setUserRefreshTokenSuccess(state, { tokenExpiredDate, token, remember }) {
            await setAuthorizedUserRefreshTokenSuccess(token, tokenExpiredDate, remember);
        }
    },
    actions: {
        async userLogin({ commit }, form) {
            await commit('setInitialState');
            const response = await fetchUserLogin(form);
            if (response.status === 200) {
                await commit('setUserLoginSuccess', response.data.data);
                commit('setAccount', await fetchAccount());
            } else {
                commit('setUserLoginError', response.data);
            }
        },
        async userLogOut() {
            await fetchUserLogOut().then(() => unsetToken());
        },
        async refreshToken({ commit }) {
            const response = await fetchRefreshToken();
            if (response.status === 200) {
                await commit('setUserRefreshTokenSuccess', response.data.data);
            } else {
                commit('setUserRefreshTokenError', response.data);
            }
        }
    }
}
