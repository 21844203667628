import {
    fetchAllProducts,
    fetchProducts,
    fetchProduct,
    deleteProduct,
    createProduct,
    updateProduct,
    fetchProductsSelectOption
} from '@/services/api/products/index';

/**
 *
 * @type {{
 * createProductError: boolean,
 * product: Product,
 * updateProductError: boolean,
 * updateProductSuccess: boolean,
 * createProductSuccess: boolean,
 * productResponseMessage: [],
 * products: Product[]
 * }}
 */
const initialState = {
    allProducts: [],
    products: [],
    product: {},
    createProductSuccess: false,
    createProductError: false,
    updateProductSuccess: false,
    updateProductError: false,
    productResponseMessage: [],
    productsSelectOption: [],
    productCount:0
};

export default {
    state: initialState,
    getters: {
        products: state => state.products,
        getProductCount: state => state.productCount,
        allProducts: state => state.allProducts,
        product: state => state.product,
        createProductSuccess: state => state.createProductSuccess,
        createProductError: state => state.createProductError,
        updateProductSuccess: state => state.updateProductSuccess,
        updateProductError: state => state.updateProductError,
        productResponseMessage: state => state.productResponseMessage,
        productsSelectOption: state => state.productsSelectOption
    },
    mutations: {
        setAllProducts(state, { data }) {
            state.allProducts = data;
        },
        setProducts(state, { data, meta }) {
            state.products = data;
            state.productCount = meta.total;
        },
        setProduct(state, { data }) {
            state.product = data;
        },
        setInitialState(state) {
            state.createProductSuccess = false;
            state.createProductError = false;
            state.updateProductSuccess = false;
            state.updateProductError = false;
            state.productResponseMessage = [];
        },
        setProductCreateSuccess(state, { data, message }) {
            state.product = data;
            state.createProductSuccess = true;
            state.productResponseMessage = message;
        },
        setCreateProductError(state, message) {
            state.createProductError = true;
            state.productResponseMessage = message;
        },
        setProductUpdateSuccess(state, { data, message }) {
            state.product = data;
            state.updateProductSuccess = true;
            state.productResponseMessage = message;
        },
        setUpdateProductError(state, message) {
            state.updateProductError = true;
            state.productResponseMessage = message;
        },
        setProductsSelectOption(state, { data }) {
            state.productsSelectOption = data;
        }
    },
    actions: {
        async getProducts({ commit }, form) {
            commit('setProducts', await fetchProducts(form.page, form.search, form.sortBy, form.sortDesc));
        },
        async getAllProducts({ commit }) {
            commit('setAllProducts', await fetchAllProducts());
        },
        async getProduct({ commit }, id) {
            commit('setProduct', await fetchProduct(id));
        },
        async deleteProduct(context, id) {
            return await deleteProduct(id);
        },
        async createProduct({ commit }, { form }) {
            await commit('setInitialState');
            const response = await createProduct(form);
            response.status === 201 ? await commit('setProductCreateSuccess', response.data)
                : await commit('setCreateProductError', response.data);
        },
        async updateProduct({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await updateProduct(id, form);
            response.status === 200 ? await commit('setProductUpdateSuccess', response.data)
                : await commit('setUpdateProductError', response.data);
        },
        async fetchProductsSelectOption({ commit }) {
               commit('setProductsSelectOption', await fetchProductsSelectOption());
        }
    }
}
