import router from '@/router';

export const getAccount = () => localStorage.getItem('is_admin');

export const setUserCredentials = async (isadmin) => await localStorage.setItem('is_admin', isadmin);

/**
 * @returns {void}
 */
export const redirectUrl = async () => {
    await router.push({ path: '/home' }).catch(() => {});
}
