import { getInstance } from '@/configs/axios';

/**
 *
 * @param form
 * @returns {Promise<any>}
 */
export const createProduct = async (form) => await getInstance().post('product', { ...form });

/**
 *
 * @param {number} id
 * @param {ProductUpdateForm} form
 * @returns {Promise<any>}
 */
export const updateProduct = async (id, form) => await getInstance().patch(`product/${id}`, form);

/**
 * @returns {Promise<Product[]>}
 */
export const fetchProducts = async (page, search, sortBy = null, sortDesc = null) => {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : '';

    const { data } = await getInstance().get(`product/list?page=${page}&search=${search}${sortParams}`);

    return data;
}

/**
 * @param {number} id
 * @returns {Promise<Product>}
 */
export const fetchProduct = async (id) => {
    const { data } = await getInstance().get(`product/${id}`);

    return data;
}

/**
 *
 * @param {number} id
 * @returns {Promise<string>}
 */
export const deleteProduct = async (id) => {
    const { data } = await getInstance().delete('product', { data: { id } });

    return data;
}

/**
 *
 * @returns {Promise<any>}
 */
export const fetchProductsSelectOption = async () => {
    const { data } = await getInstance().get('product/select-options');

    return data;
}


