import { mapActions, mapGetters } from 'vuex';
import handleChangeLocale from '@/helpers/handleChangeLocale';
import moment from 'moment';

export const withApp = {
    data(){
        return {
            duration: '',
            handleRefresh: ''
        }
    },
    computed: {
        ...mapGetters(['account'])
    },
    watch: {
        account(newValue) {
            newValue && handleChangeLocale(newValue.lang);
        },
        $route(to){
            !to.meta.auth && clearInterval(this.handleRefresh);
        }
    },
    mounted() {
       this.$router.onReady(() => {
           if (this.$route.meta.auth) {
               this.appLayoutGetAccount();
               this.checkDuration()
           }
       });
    },
    methods: {
        ...mapActions({
            appLayoutGetAccount: 'getAccount',
            handleRefreshToken: 'refreshToken'
        }),
        checkDuration() {
            clearInterval(this.handleRefresh);
            this.handleRefresh = setInterval(() => {
                this.checkExpiredMinutes();
                // console.log('refreshToken::setInterval');
            }, 40000);
        },
        async checkExpiredMinutes() {
            const rememberToken = localStorage.getItem('remember');
            if (rememberToken === 'true') {
                this.handleCompareExpiredDate();
                this.duration < 15 && await this.handleRefreshToken();
            }
        },
        handleCompareExpiredDate() {
            const expiredDate = moment(localStorage.getItem('tokenExpiredDate'));
            this.duration = moment.duration(expiredDate.diff(moment())).minutes();
        }
    }
}
