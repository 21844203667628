import {
    fetchAllBoards,
    fetchAllListing,
    fetchBoard,
    fetchBoardsOption,
    fetchChangeListingPosition,
    fetchCreateBoard,
    fetchCreateBoardListing,
    fetchUpdateBoard,
    fetchDeleteBoard,
    fetchUpdateBoardCardPosition,
    deleteListing,
    updateListingData
} from '@/services/api/board';


const initialState = {
    boardsOption: [],
    boardsWithTasks: [],
    board: {},
    isCreateBoardListing: false,
    isCreateBoardListingMessage: '',
    isCreateBoard: false,
    isCreateBoardMessage: '',
    createdNewListing: {},
    allListing:[]
}

export default {
    state: initialState,
    getters: {
        getBoards: state => state.boardsOption,
        getBoardsWithTasks: state => state.boardsWithTasks,
        getBoard: state => state.board,
        getListings: state => state.board.listings,
        getIsCreateBoardListing: state => state.isCreateBoardListing,
        getIsCreateBoardListingMessage: state => state.isCreateBoardListingMessage,
        getIsCreateBoard: state => state.isCreateBoard,
        getIsCreateBoardMessage: state => state.isCreateBoardMessage,
        getCreatedNewListing: state => state.createdNewListing,
        getAllListings: state => state.allListing
    },
    mutations: {
        setBoardsOption(state, { data }) {
            state.boardsOption = data;
        },
        setBoardsWithTasks(state, { data }) {
            state.boardsWithTasks = data;
        },
        setBoard(state, { data }) {
            state.board = data;
        },
        setBoardListing(state, { data, message }) {
            state.createdNewListing = data;
            state.isCreateBoardListing = true;
            state.isCreateBoardListingMessage = message;
        },
        setCreateBoard(state, { data, message }) {
            state.isCreateBoard = true;
            state.isCreateBoardMessage = message;
            state.boardsWithTasks = [...state.boardsWithTasks, data];
        },
        setInitialState(state) {
            state.isCreateBoardListing = false;
            state.createBoardListingMessage = '';
            state.isCreateBoard = false;
            state.createdNewListing = {};
            state.isCreateBoardMessage = '';
        },
        setListings(state, data){
            state.allListing = data
        },
        filterListings(state, id){
            state.board.listings = [...state.board.listings.filter(item => item.id !== id)];
        }
    },
    actions: {
        add_listings({ commit }, listings){
            commit('setListings', listings)
        },
        async fetchBoardsOption({ commit }) {
            commit('setBoardsOption', await fetchBoardsOption());
        },
        async fetchAllBoards({ commit }) {
            commit('setBoardsWithTasks', await fetchAllBoards());
        },
        fetchUpdateBoardCardPosition({ commit }, { id, board_id, move }) {
            fetchUpdateBoardCardPosition(id, board_id, move);
        },
        async fetchCreateBoard({ commit }, form) {
            commit('setInitialState');
            commit('setCreateBoard', await fetchCreateBoard(form));
        },
        async fetchBoard({ commit }, id) {
            commit('setBoard', await fetchBoard(id));
        },
        async fetchCreateBoardListing({ commit }, { id, form }) {
            commit('setInitialState');
            commit('setBoardListing', await fetchCreateBoardListing(id, form));
        },
        fetchChangeListingPosition({ commit }, { id, listing_id, move }) {
            fetchChangeListingPosition(id, listing_id, move);
        },
        fetchUpdateBoard({ commit }, { id, form }) {
            return fetchUpdateBoard(id, form);
        },
        fetchDeleteBoard({ commit }, { id }) {
            return fetchDeleteBoard(id);
        },
        async fetchAllListings({ commit }) {
            commit('setInitialState');
            commit('setListings', await fetchAllListing());
        },
        fetchDeleteListing({ commit }, id){
            commit('setInitialState');
            return deleteListing(id);
        },
        updateListing({ commit }, data){
            commit('setInitialState');
            return updateListingData(data)
        }
    }
}
