import {
  fetchInvoiceConnector,
  fetchInvoiceConnectors,
  createInvoiceConnector,
  updateInvoiceConnector,
  deleteInvoiceConnector,
  fetchCreateApartment,
  fetchUpdateApartment,
  fetchDeleteApartment
} from '@/services/api/invoiceConnectors';

/**
 *
 * @type {{invoiceConnectors: InvoiceConnector[], invoiceConnector: InvoiceConnector}}
 */
const initialState = {
  invoiceConnectors: [],
  invoiceConnector: {},
  updateInvoiceConnectorSuccess: false,
  updateInvoiceConnectorError: false,
  createInvoiceConnectorSuccess: false,
  createInvoiceConnectorError: false,
  InvoiceConnectorResponseMessage: ''
};

export default {
  state: initialState,
  getters: {
    invoiceConnectors: state => state.invoiceConnectors,
    invoiceConnector: state => state.invoiceConnector,
    updateInvoiceConnectorSuccess: state => state.updateInvoiceConnectorSuccess,
    updateInvoiceConnectorError: state => state.updateInvoiceConnectorError,
    invoiceConnectorResponseMessage: state => state.InvoiceConnectorResponseMessage,
    createInvoiceConnectorSuccess: state => state.createInvoiceConnectorSuccess,
    createInvoiceConnectorError: state => state.createInvoiceConnectorError
  },
  mutations: {
    setInvoiceConnectors(state, payload) {
      state.invoiceConnectors = payload;
    },
    setInvoiceConnector(stat, data) {
      stat.invoiceConnector = data;
    },
    setInitialState(state) {
      state.updateInvoiceConnectorSuccess = false;
      state.updateInvoiceConnectorError = false;
      state.createInvoiceConnectorSuccess = false;
      state.createInvoiceConnectorError = false;
    },
    setInvoiceConnectorUpdateSuccess(state, { data, message }) {
      state.invoiceConnector = data;
      state.updateInvoiceConnectorSuccess = true;
      state.invoiceConnectorResponseMessage = message;
    },
    setInvoiceConnectorUpdateError(state, message) {
      state.updateInvoiceConnectorSuccess = false;
      state.updateInvoiceConnectorError = true;
      state.invoiceConnectorResponseMessage = message;
    },
    setInvoiceConnectorCreateSuccess(state, { data, message }) {
      state.invoiceConnector = data;
      state.createInvoiceConnectorSuccess = true;
      state.createInvoiceConnectorError = false;
      state.InvoiceConnectorResponseMessage = message;
    },
    setInvoiceConnectorCreateError(state, message) {
      state.createInvoiceConnectorError = true;
      state.invoiceConnectorResponseMessage = message;
    }
  },
  actions: {
    async getInvoiceConnector({ commit }, id) {
      await commit('setInitialState');
      //console.log('aaaa', await fetchInvoiceConnector(id))
      commit('setInvoiceConnector', await fetchInvoiceConnector(id));
    },
    async getInvoiceConnectors({ commit }) {
      await commit('setInitialState');
      commit('setInvoiceConnectors', await fetchInvoiceConnectors());
    },
    async createInvoiceConnector({ commit }, { form }) {
      await commit('setInitialState');
      const response = await createInvoiceConnector(form);
      response.status === 201 ? await commit('setInvoiceConnectorCreateSuccess', response.data)
          : await commit('setInvoiceConnectorCreateError', response.data);
    },
    async updateInvoiceConnector({ commit }, { form }) {
      await commit('setInitialState');
      const response = await updateInvoiceConnector(form);
      response.status === 200 ? await commit('setInvoiceConnectorUpdateSuccess', response.data)
          : await commit('setInvoiceConnectorUpdateError', response.data);
    },
    async fetchDeleteInvoiceConnector(context, id) {
      return await deleteInvoiceConnector(id);
    },
    async addApartment({ commit }, { id, form }){
      await commit('setInitialState')
      commit('setInvoiceConnector', await fetchCreateApartment(id, form));
    },
    async updateApartment({ commit }, { id, apartment }){
      await commit('setInitialState')
      commit('setInvoiceConnector', await fetchUpdateApartment(id, apartment));
    },
    async deleteApartment({ commit }, { id, apartmentId }){
      await commit('setInitialState')
      commit('setInvoiceConnector', await fetchDeleteApartment(id, apartmentId));
    }
  }
};
