export default [
    {
        path: '/public/space',
        component: () => import('@/views/PublicPages/PublicPage'),
        children: [
            {
                path: ':uuid',
                component: () => import('@/views/PublicPages/PublicBoards/PublicBoards'),
                meta: {
                    auth: false,
                    title: 'account.header.title'
                }
            },
            {
                path: ':uuid/board/:boardUuid',
                component: () => import('@/views/PublicPages/PublicListings/PublicListings'),
                name: 'public-listings',
                meta: {
                    auth: false,
                    headerBackToActions: true,
                    title: 'account.header.title'
                }
            },
            {
                path: ':uuid/task/:taskUuid',
                component: () => import('@/views/PublicPages/PublicTask/PublicTask'),
                name: 'public-task-show',
                meta: {
                    auth: false,
                    headerBackToActions: true,
                    title: 'account.header.title'
                }
            }
        ]
    }
];
