import auth from '@/router/middlewares/auth';

const staticPathName = 'boards';

export default [
    {
        path: '/boards',
        component: () => import(/* webpackChunkName: "BoardView" */'@/views/Boards/Boards'),
        meta: {
            breadCrumb: 'board.breadCrumb',
            breadCrumbHidden: true
        },
        children: [
            {
                path: '/',
                name: 'boards',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Boards/BoardsList/BoardsList'),
                meta: {
                    headerBackToActions: false,
                    headerIsCreateBoardDropDown: true,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title'
                }
            }
        ]
    },
    {
        path: '/board',
        component: () => import(/* webpackChunkName: "BoardView" */'@/views/Boards/Boards'),
        meta: {
            breadCrumb: 'board.breadCrumb'
        },
        children: [
            {
                path: ':id',
                name: 'board-listings',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Boards/Show/BoardListings'),
                meta: {
                    headerIsCreateTaskActions: true,
                    headerGoBackIcon: true,
                    breadCrumbDisable: true,
                    // breadCrumbHidden: true,
                    auth: true,
                    middleware: auth,
                    staticPathName,
                    title: 'account.header.title'
                }
            },
            {
                path: ':id/task',
                component: () => import(/* webpackChunkName: "TaskView"*/ '@/views/Task/Task'),
                meta: {
                    breadCrumb: 'task.header.createEditPage'
                },
                children:[
                    {
                        path: '/',
                        redirect: { name: 'boards' }
                    },
                    {
                        path: 'create',
                        name: 'task-create',
                        component: () => import('@/views/Task/CreateOrUpdate/CreateOrUpdate'),
                        meta: {
                            auth: true,
                            middleware: auth,
                            breadCrumb: 'task.header.create',
                            headerBackToActions: true,
                            breadCrumbDisable: true,
                            saveAction: true,
                            staticPathName,
                            title: 'account.header.title'
                        }
                    },
                    {
                        path: ':taskId/edit',
                        name: 'task-edit',
                        component: () => import('@/views/Task/Show/Task'),
                        meta: {
                            auth: true,
                            headerBackToActions: true,
                            breadCrumbDisable: true,
                            editableAction: true,
                            middleware: auth,
                            staticPathName,
                            title: 'account.header.title'
                        }
                    },
                    {
                        path: ':taskId',
                        name: 'task-show',
                        component: () => import('@/views/Task/Show/Task'),
                        meta: {
                            auth: true,
                            editableAction: true,
                            headerBackToActions: true,
                            breadCrumbDisable: true,
                            middleware: auth,
                            staticPathName,
                            title: 'account.header.title'
                        }
                    }
                ]
            }
        ]
    }
]
