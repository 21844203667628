import {
    fetchLabels,
    fetchLabel,
    createLabel,
    updateLabel,
    deleteLabel
} from '@/services/api/label';

/**
 *
 * @type {{labels: label[], label: label}}
 */
const initialState = {
    labels: [],
    label: {},
    updateLabelSuccess: false,
    updateLabelError: false,
    createLabelSuccess: false,
    createLabelError: false,
    deleteLabelSuccess: false,
    deleteLabelError: false,
    labelResponseMessage: ''
};

export default {
    state: initialState,
    getters: {
        labels: state => state.labels,
        label: state => state.label,
        updateLabelSuccess: state => state.updateLabelSuccess,
        updateLabelError: state => state.updateLabelError,
        createLabelSuccess: state => state.createLabelSuccess,
        createLabelError: state => state.createLabelError,
        deleteLabelSuccess: state => state.deleteLabelSuccess,
        deleteLabelError: state => state.deleteLabelError,
        labelResponseMessage: state => state.labelResponseMessage
    },
    mutations: {
        setLabels(state, payload) {
            state.labels = payload.data;
        },
        setLabel(stat, { data }) {
            stat.label = data;
        },
        setInitialState(state) {
            state.updateLabelError = false;
            state.createLabelSuccess = false;
            state.createLabelError = false;
            state.deleteLabelSuccess = false;
            state.deleteLabelError = false;
            state.updateLabelSuccess = false;
            state.labelResponseMessage = ''
        },
        setLabelUpdateSuccess(state, { data, message }) {
            state.label = data;
            const selectedLabels = Object.assign(state.labels, [])
            selectedLabels.map(item => {
                if (item.id === data.id){
                    item.color = data.color
                    item.title = data.title
                }
            })
            state.labels = selectedLabels
            state.updateLabelSuccess = true;
            state.labelResponseMessage = message;
        },
        setLabelUpdateError(state, message) {
            state.updateLabelSuccess = false;
            state.updateLabelError = true;
            state.labelResponseMessage = message;
        },
        setLabelCreateSuccess(state, { data, message }) {
            state.label = data;
            state.labels.push(data)
            state.createLabelSuccess = true;
            state.createLabelError = false;
            state.labelResponseMessage = message;
        },
        setLabelCreateError(state, message) {
            state.createLabelError = true;
            state.labelResponseMessage = message;
        },
        setLabelDeleteSuccess(state, { id, message }) {
            state.labels = state.labels.filter(label => label.id !== id);
            state.deleteLabelSuccess = true;
            state.deleteLabelError = false;
            state.labelResponseMessage = message;
        },
        setLabelDeleteError(state, message) {
            state.deleteLabelError = true;
            state.labelResponseMessage = message;
        }
    },
    actions: {
        async getLabel({ commit }, id) {
            await commit('setInitialState');
            commit('setLabel', await fetchLabel(id));
        },
        async getLabels({ commit }) {
            await commit('setInitialState');
            commit('setLabels', await fetchLabels());
        },
        async fetchCreateLabel({ commit }, { form }) {
            await commit('setInitialState');
            const response = await createLabel(form);
            response.status === 201 ? await commit('setLabelCreateSuccess', response.data)
                : await commit('setLabelCreateError', response.data);
        },
        async fetchUpdateLabel({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await updateLabel(id, form);
            response.status === 201 ? await commit('setLabelUpdateSuccess', response.data)
                : await commit('setLabelUpdateError', response.data);
        },
        async fetchDeleteLabel({ commit }, id) {
            await deleteLabel(id).
            then(async response => {
                await commit('setLabelDeleteSuccess', { id:id, message:response.message })
                commit('setInitialState')
            }).catch(error => {
                commit('setLabelDeleteError', error)
            });

        }
    }
};
