import { getInstance } from '@/configs/axios';
import { CSRFRequest } from '@/services/api/auth/csrf';

/**
 *
 * @param {LoginInterface}form
 * @returns {Promise<any>}
 */
export const fetchUserLogin = async (form) => {
    await CSRFRequest();
    return await getInstance().post('login', { ...form });
}

/**
 *
 * @returns {Promise<any>}
 */
export const fetchUserLogOut = async () => {
    await CSRFRequest();
    return await getInstance().post('logout');
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchRefreshToken = async () => {
    return await getInstance().get('refresh');
}
