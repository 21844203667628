import { getInstance } from '@/configs/axios';

export const fetchAccount = async () => {
    const { data } = await getInstance().get('account');

    return data;
}

export const fetchUploadAccountImage = async (form) => {
  const { data } = await getInstance().post('account/avatar', form, {
        'Content-Type': 'multipart/form-data'
    });

  return data;
}

export const fetchUpdateAccountSettings = async (form) => await getInstance().patch('account', form);

export const fetchUpdateAppLanguage = async (form) => await getInstance().post('settings/locale', form);

export const fetchDeleteAccountAvatar = async () => await getInstance().delete('account/avatar');

export const fetchUpdateCalendarIdSettings =async (form) => await getInstance().put('account/calendarId', form);
