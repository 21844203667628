import auth from '@/router/middlewares/auth';
import isadmin from '@/router/middlewares/isadmin';

export default [
    {
        path: '/users',
        name: '',
        component: () => import(/* webpackChunkName: "AccountView"*/ '@/views/Users/Users'),
        meta: {
            headerBackToActions: true,
            breadCrumb: 'account.header.titleUser',
            auth: true,
            middleware: [auth, isadmin],
            editableAction: false,
            headerAddNewAction: true,
            title: 'account.header.title'
        },
        children: [
            {
                path: '',
                name: 'user',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Users/UsersTable/UsersTable'),
                meta: {
                    headerBackToActions: true,
                    breadCrumb: 'account.header.titleCreate',
                    auth: true,
                    middleware: [auth, isadmin],
                    editableAction: false,
                    headerAddNewAction: true,
                    title: 'account.header.title'
                }
            }
        ]
    },
    {
        path: '/user',
        name: '',
        component: () => import(/* webpackChunkName: "AccountView"*/ '@/views/Users/Users'),
        meta: {
            headerBackToActions: true,
            breadCrumb: 'account.header.titleUser',
            auth: true,
            middleware: [auth, isadmin],
            editableAction: false,
            headerAddNewAction: true,
            title: 'account.header.title'
        },
        children: [
            {
                path: 'create',
                name: 'user-create',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Users/UserEditCreate/UserCreate'),
                meta: {
                    headerBackToActions: true,
                    breadCrumb: 'account.header.titleCreate',
                    auth: true,
                    middleware: [auth, isadmin],
                    saveAction: true,
                    editableAction: false,
                    headerAddNewAction: false,
                    title: 'account.header.title'
                }
            },
            {
                path: ':id/edit',
                name: 'user-edit',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Users/UserEditCreate/UserUpdate'),
                meta: {
                    headerBackToActions: true,
                    breadCrumb: 'account.header.titleCreate',
                    auth: true,
                    middleware: [auth, isadmin],
                    saveAction: true,
                    headerAddNewAction: false,
                    title: 'account.header.title',
                    breadCrumbHidden: true
                }
            },
            {
                path: ':id',
                name: 'user-show',
                component: () => import(/* webpackChunkName: "BoardView" */'@/views/Users/UserEditCreate/UserShow'),
                meta: {
                    editableAction: true,
                    customEdit: true,
                    headerBackToActions: true,
                    breadCrumb: 'account.header.titleCreate',
                    auth: true,
                    middleware: [auth, isadmin],
                    headerAddNewAction: false,
                    title: 'account.header.title',
                    breadCrumbHidden: true
                }
            }
        ]
    }
]
