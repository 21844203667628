<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { withApp } from '@/mixins/withApp';
import { mapActions } from 'vuex';

export default {
  name: 'App',
  mixins: [withApp],
  mounted() {
    if (localStorage.getItem('token') !== null) {
        this.refreshToken();
    }
  },
  methods: {
    ...mapActions({
      refreshToken: 'refreshToken'
    })
  }
};
</script>

<style>
</style>

