import {
    fetchUsers,
    fetchUser,
    createUser,
    updateUser,
    deleteUser,
    fetchUsersWithoutPagination
} from '@/services/api/user';

/**
 *
 * @type {{users: user[], user: user}}
 */
const initialState = {
    users: [],
    usersWithoutPagination: [],
    user: {},
    updateUserSuccess: false,
    updateUserError: false,
    createUserSuccess: false,
    createUserError: false,
    userResponseMessage: ''
};

export default {
    state: initialState,
    getters: {
        users: state => state.users,
        usersWithoutPagination: state => state.usersWithoutPagination,
        user: state => state.user,
        updateUserSuccess: state => state.updateUserSuccess,
        updateUserError: state => state.updateUserError,
        createUserSuccess: state => state.createUserSuccess,
        createUserError: state => state.createUserError,
        userResponseMessage: state => state.userResponseMessage
    },
    mutations: {
        setUsers(state, payload) {
            state.users = payload.data;
        },
        setUsersWithoutPagination(state, payload) {
            state.usersWithoutPagination = payload.data;
        },
        setUser(stat, { data }) {
            stat.user = data;
        },
        setInitialState(state) {
            state.updateUserSuccess = false;
            state.updateUserError = false;
            state.createUserSuccess = false;
            state.createUserError = false;
            state.userResponseMessage = ''
        },
        setUserUpdateSuccess(state, { data, message }) {
            state.user = data;
            state.updateUserSuccess = true;
            state.userResponseMessage = message;
        },
        setUserUpdateError(state, message) {
            state.updateUserSuccess = false;
            state.updateUserError = true;
            state.userResponseMessage = message;
        },
        setUserCreateSuccess(state, { data, message }) {
            state.user = data;
            state.createUserSuccess = true;
            state.createUserError = false;
            state.userResponseMessage = message;
        },
        setUserCreateError(state, message) {
            state.createUserError = true;
            state.userResponseMessage = message;
        }
    },
    actions: {
        async getUser({ commit }, id) {
            await commit('setInitialState');
            commit('setUser', await fetchUser(id));
        },
        async getUsers({ commit }) {
            await commit('setInitialState');
            commit('setUsers', await fetchUsers());
        },
        async getUsersWithoutPagination({ commit }) {
            await commit('setInitialState');
            commit('setUsersWithoutPagination', await fetchUsersWithoutPagination());
        },
        async createUser({ commit }, { form }) {
            await commit('setInitialState');
            const response = await createUser(form);
            response.status === 201 ? await commit('setUserCreateSuccess', response.data)
                : await commit('setUserCreateError', response.data);
        },
        async updateUser({ commit }, { id, form }) {
            await commit('setInitialState');
            const response = await updateUser(id, form);
            response.status === 201 ? await commit('setUserUpdateSuccess', response.data)
                : await commit('setUserUpdateError', response.data);
        },
        async fetchDeleteUser(context, id) {
            return await deleteUser(id);
        }
    }
};
